import includes from 'lodash/includes';
import isObject from 'lodash/isObject';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy'
import { getLocaleFromLanguageCode } from '../../../intl-helpers';
import get from 'lodash/get';
import { logAnalytic } from '../../../api';
import find from 'lodash/find';

const IGNORED_KEYS = [
    'nome_produttore',
    'codice_articolo_produttore',
    'descrizione_articolo_produttore',
    'descrizione_corta_produttore',
]

const TYPE_ATTR = 'type'
const CODICE_ARTICOLO_ATTR = 'codice_articolo'


export const getFileName = (fileName) => {
    if (fileName !== null) {
        return fileName.split(/([\\/])/g).pop();
    }
}

export const getArticleDescription = (data, language) => {
    const identificazione = find(data, { property: 'identificazione' });

    if (!identificazione) {
        return '-';
    }

    const description = find(identificazione.children, { property: 'descrizione_articolo' })

    if (!description) {
        return '-'
    }

    return get(description, `value.${getLocaleFromLanguageCode(language)}`, '-')
}

export const logDownload = (user, filename) => {
    const { articleDetails } = this.props;

    const locale = getLocaleFromLanguageCode(this.props.i18n.language);

    const logData = {
        utente: `${user.nome} ${user.cognome}`,
        brand: get(articleDetails, `identificazione.sigla_marca.value.label.${locale}`),
        acronym: get(articleDetails, 'identificazione.sigla_marca.value.code'),
        code: get(articleDetails, 'identificazione.codice_articolo.value'),
        code_description: get(
            articleDetails,
            `identificazione.descrizione_articolo.value.${locale}`
        ),
        operation: 'Download asset',
        subject: filename,
    };

    logAnalytic(logData);
}

export const getFilteredAttrs = (section) => {
    return section.filter((a) => !includes(IGNORED_KEYS, a.key))
}

export const renderAttrValue = ({key, value}) => {
    if (typeof value === 'object' && value?.unit?.symbol) {
        return `${value.amount} ${value.unit.symbol}`; // Formatta con "amount" e "symbol"
    }

    if (isObject(value)) {
        return JSON.stringify(value)
    }

    return value
}

export const getFilteredAssetsAttributes = (props, stateSortBy, stateSortDirection) => {
    const { attributes, selectedFilter, extFilter } = props;

    if (selectedFilter === 'all' && extFilter === 'all' && stateSortBy === null) {
        return attributes;
    }

    let filteredAttributes = attributes;

    if (selectedFilter !== 'all') {
        filteredAttributes = filter(attributes, (attribute) => {
            const klasses = attribute.asset_classes.map((k) => k.code);

            return includes(klasses, selectedFilter);
        });
    }

    if (extFilter !== 'all') {
        filteredAttributes = filter(filteredAttributes, (attribute) => {
            return attribute.ext === extFilter;
        });
    }

    if (stateSortBy === null) {
        return filteredAttributes;
    }

    const sorted = sortBy(filteredAttributes, stateSortBy);

    if (stateSortDirection === 'asc') {
        return sorted;
    }

    return sorted.reverse();
}

export const getFilteredArticoliCorrelati = (props, stateSortBy, stateSortDirection) => {
    const { attributes, selectedFilter } = props;

    let filtered = attributes;

    if (selectedFilter !== 'all') {
        filtered = filter(filtered, (a) => {
            return a.type === selectedFilter;
        });
    }

    if (stateSortBy === null) {
        return sortBy(filtered, [TYPE_ATTR, CODICE_ARTICOLO_ATTR]);
    }

    const toSortBy =
        stateSortBy !== TYPE_ATTR ? stateSortBy : [TYPE_ATTR, CODICE_ARTICOLO_ATTR];

    const sorted = sortBy(filtered, toSortBy);

    if (stateSortDirection === 'asc') {
        return sorted;
    }

    return sorted.reverse();
}