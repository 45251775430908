import React from 'react'
import {
    excludedProperties,
    getIntegrationValue,
    removeEmptyProperties
} from './IntegrationsUtils';
import PrezzoCalcolatoTableBody from './PrezzoCalcolatoTableBody'
import PrezzoCalcolatoTableHeader from './PrezzoCalcolatoTableHeader'

const SectionIntegration = ({integration}) => {
    const integrationChildren = integration.children
    //console.table(integrationChildren)
    const integrationFiltered = integrationChildren.filter(
        item => !excludedProperties.includes(item.property)
    )

    // Group the date into single array
    const dateProperties = ['datainiziovalidita', 'dataterminevalidita', 'datainiziovaliditavendita', 'dataterminevaliditavendita']
    const integrationsDates = integrationChildren.filter(
        item => dateProperties.includes(item.property)
    )
    // Group code and description into single array
    const codeDescriptionProperties = ['codiceconvenzione', 'descrizioneconvenzione']
    const integrationsCodeDescription = integrationChildren.filter(
        item => codeDescriptionProperties.includes(item.property)
    )

    const prezziCalcolatiObject = integrationChildren.find(item => item.property === "prezzi_calcolati")
    const prezziCalcolati = prezziCalcolatiObject?.children ? prezziCalcolatiObject.children : null

    const prezziCalcolatiFiltered = removeEmptyProperties(prezziCalcolati)

    const prezziCalcolatiHeading = prezziCalcolatiFiltered[0]?.children ? prezziCalcolatiFiltered[0] : null

    return (
        <div className="section-integrations container-full-width overflow-hidden">

            <table className="table table-title-everprice-convention">
                <tbody>
                    <tr>
                       <td className="text-left">{getIntegrationValue(integrationsCodeDescription[0])} - {getIntegrationValue(integrationsCodeDescription[1])}</td>
                    </tr>
                </tbody>
            </table>
            <table
                key={integration.property}
                className="table table-striped table-striped-alt table-hover table-article-info">

                <tbody>
                {/*{*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            {integrationsDates[0].label['it_IT']} - {integrationsDates[1].label['it_IT']}*/}
                {/*        </td>*/}
                {/*        <td>*/}
                {/*            {getIntegrationValue(integrationsDates[0])} - {getIntegrationValue(integrationsDates[1])}*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*}*/}


                {
                    integrationFiltered.map((integrationChild, index) => (

                        <tr key={integrationChild.property + index}>
                            <td>
                                {integrationChild?.label['it_IT'] ? integrationChild.label['it_IT'] : ''}
                            </td>

                            <td>
                                <strong>{getIntegrationValue(integrationChild)}</strong>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

                <div
                    className="overflow-x-scroll mt-3"
                >
                    <table className="table table-striped table-auto overflow-x-auto">
                        <thead>
                            <PrezzoCalcolatoTableHeader
                                prezzoCalcolatoHeading={prezziCalcolatiHeading}
                            />
                        </thead>
                        <tbody>
                        {
                            prezziCalcolatiFiltered.map((prezzoCalcolato, index) => (
                                <PrezzoCalcolatoTableBody
                                    key={index}
                                    prezzoCalcolato={prezzoCalcolato}
                                />

                            ))
                        }
                        {/*{JSON.stringify(prezziCalcolati, null, 2)}*/}
                        </tbody>
                    </table>
                </div>
            </div>
            )
            }

            export default SectionIntegration