import { format } from 'date-fns'
import { getIntlNumberFormat } from '../../../../intl-helpers'
const intl = getIntlNumberFormat()

// Date in ISO format to "d/m/Y"
export const dateFromDatetime = (datetime) => {
    if (!datetime) return null
    const date = new Date(datetime)
    if (isNaN(date)) return null
    return format(date, 'DD/MM/YYYY')
}

// Timestamp (millseconds) in "d/m/Y"
export const dateFromTimestamp = (timestamp) => {
    if (!timestamp) return null
    const date = new Date(parseInt(timestamp, 10))
    if (isNaN(date)) return null
    return format(date, 'DD/MM/YYYY')
}

// Integrations main value
export const getIntegrationValue = (item) => {
    if(!item) return null
    const regexDate = /(datainiziovalidita|dataterminevalidita|launched)/i

    if(!regexDate.test(item.property)) return item.value

    switch (item.property) {
        case 'datainiziovalidita':
        case 'dataterminevalidita':
        case 'datainiziovaliditavendita':
        case 'dataterminevaliditavendita':
            return dateFromDatetime(item.value)
        case 'launched':
            return dateFromTimestamp(item.value)
        default:
            return item.value
    }
}

// Integrations prices ( prezzi calcolati )
export const getAttributeValue = (item) => {

    const regexPrice = /(listino|prezzo|costo|pervalore)/i
    const regexNumber = /(perquantita)/i
    const regexDate = /(adata|dadata)/i

    if (regexDate.test(item.property)) {
        const dateValue = item.value

        if (!dateValue || !/^\d{4}-\d{2}-\d{2}$/.test(dateValue)) {
            return item.value
        }

        const [year, month, day] = dateValue.split('-');
        return `${day}/${month}/${year}`
    }

    if (regexPrice.test(item.property)) {
        const numericValue = parseFloat(item.value)
        if (isNaN(numericValue)) return item.value

        if(numericValue === 0) return ''

        return (
            <>
                {/*€ {numericValue.toFixed(2).replace('.', ',')}*/}
                € {numericValue !== 0 ? intl.format(numericValue) : ''}
            </>
        );
    }

    if(regexNumber.test(item.property)) {
        const numericValue = parseFloat(item.value)
        if (isNaN(numericValue)) return item.value

        return (
            <>
                {/*{numericValue.toFixed(2).replace('.', ',')}*/}
                {numericValue !== 0 ? intl.format(numericValue) : ''}
            </>
        )
    }

    return item.value
}


export const sortChildrenProperties = (item, orderedProperties) => {
    const desiredOrder = orderedProperties ? orderedProperties : []

    item.children.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.property);
        const indexB = desiredOrder.indexOf(b.property);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }

        // Se uno dei due non è nell'ordine desiderato, lo metti alla fine
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return 0;
    })

    return item.children

}

// Remove empty properties from array of object
export const removeEmptyProperties = (arr) => {
    // Extract all children properties
    const allProperties = new Set()

    arr.forEach(item => {
        item.children.forEach(child => {
            allProperties.add(child.property)
        })
    })

    // For each property, check if in all objects there is no value
    // For no value we mean value === null, value === "" or value === 0
    const propertiesAlwaysEmpty = []

    for (let prop of allProperties) {
        let alwaysEmpty = true

        for (let obj of arr) {
            const child = obj.children.find(c => c.property === prop)
            if (child) {
                const val = child.value
                // We consider empty value if  val === null, val === "" o val === 0
                if (val !== null && val !== "" && val !== 0) {
                    alwaysEmpty = false
                    break
                }
            } else {
                // If the property doesn't exist in child
                // we return alwaysEmpty true
            }
        }

        if (alwaysEmpty) {
            propertiesAlwaysEmpty.push(prop)
        }
    }

    // For each object (item) in the original 'arr' array, create a new object
    // by copying all of its properties with the spread operator and replacing
    // its 'children' array with a filtered version that removes all child objects
    // whose 'property' field is included in propertiesAlwaysEmpty.
    return arr.map(item => {
        return {
            ...item,
            children: item.children.filter(child => !propertiesAlwaysEmpty.includes(child.property))
        }
    })
}

export const desideredOrder = [
    'dadata',
    'adata',
    'pervalore',
    'perquantita',
    'prezzolistinofornitore',
    'costoacquisto',
    'costomaggiorato',
    'listinovendita1',
    'listinovendita2',
    'listinovendita3',
    'listinovendita4',
    'listinovendita5',
    'listinovendita6',
    'listinovendita7',
    'listinovendita8',
    'listinovendita9',
    'listinovendita10',
    'note'
]

// export const excludedProperties = ['siglafornitore', 'siglamarca', 'prezzi_calcolati', 'datainiziovalidita', 'dataterminevalidita', 'codiceconvenzione', 'descrizioneconvenzione']
export const excludedProperties = ['siglafornitore', 'siglamarca', 'prezzi_calcolati', 'codiceconvenzione', 'descrizioneconvenzione']