import { put, select } from 'redux-saga/effects'
import {  toggleTreeItem } from '../../actions/sectorsActions';

function generateRangeValues(valueArray) {
    return valueArray.map(([min, max]) => `${min}::${max}`)
}

// Helper function for avoid toggle effect
function* checkAndToggle(filter, selector) {
    const selectedItem = yield select(selector);
    if (filter && filter !== selectedItem) {
        yield put(toggleTreeItem(filter));
    }
}

export {
    checkAndToggle,
    generateRangeValues,
}
