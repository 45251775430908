import React from 'react'
import * as constants from '../../../constants';
import RenderCorrelatiList from './RenderCorrelatiList';
import RenderSimpleTextList from './RenderSimpleTextList';
import RenderMisureList from './RenderMisureList';


const RenderTextList = (
    {
        data,
        attributes,
        category,
        type,
        sortBy,
        sortDirection,
        toggleSort,
        getSorting,
    }
) => {

    if(category.key === 'misure'){
        return (
            <RenderMisureList
                attributes={attributes}
                type={type}
            />
        )
    }
    const isCorrelati = category.key === constants.SECTION_KEY_CORRELATI

    return isCorrelati ? (
        <RenderCorrelatiList
            data={data}
            sortBy={sortBy}
            sortDirection={sortDirection}
            toggleSort={toggleSort}
            getSorting={getSorting}
        />
    )
        :

    (
        <RenderSimpleTextList
            attributes={attributes}
            type={type}
        />
    )
}

export default RenderTextList