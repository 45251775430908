import { call, put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { actions as sectorActions } from '../../actions/sectorsActions';
import { getFilterFromLocalStorage, updateLocalStorage } from '../../utils/LocalStorageFilterUtils';
import { setProductClassValue } from '../../actions/productClassesActions';
import { selectors as productClassReducerSelectors } from '../../reducers/productClassesReducer';

function* loadEtimFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadEtimFilters')
    const storedEtimClass = storedFilters.etim_class || null

    if(storedEtimClass) yield put(sectorActions.setSelectedItemTree(storedEtimClass))

    yield call(loadValues, storedFilters)

    emitSagaEvent('saga_end')
    console.log('saga_end from loadEtimFilters')
}

function* loadValues(storedFilters){
    if(storedFilters?.etim_class){
        const etimClassFilterOptions = yield select(productClassReducerSelectors.getFilter)

        const etimClassFilters = storedFilters.etim_class

        const matchedOption = etimClassFilterOptions.options.find(option => option.value === etimClassFilters)

        yield put(setProductClassValue(matchedOption))
    }
}
// Clear the etim_class from Storage.
// Note: productClass filter is the etim_class payload.
function* removeProductClassFilter(){
    const storedProductClass = getFilterFromLocalStorage('etim_class')
    if(storedProductClass){
        updateLocalStorage('etim_class', '', 'remove')
    }
    yield put(setProductClassValue(null))
}
export { loadEtimFilters, removeProductClassFilter }