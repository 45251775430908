import * as constants from '../../../constants';
import cx from 'classnames';
import { getFilteredAttrs, renderAttrValue } from './ItemDataCategoryTableUtils';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import React from 'react';

const RenderMisureList = ({ attributes, type }) => {
    const isRepeatable = type === constants.SECTION_TYPE_REPEATABLE;
    const sections = isRepeatable ? attributes : [attributes];

    // Metodo per verificare se una stringa è JSON valida
    // const parseJSON = (value) => {
    //     try {
    //         return JSON.parse(value);
    //     } catch (e) {
    //         return null;
    //     }
    // };


    const getDisplayValue = (value) => {
        if (typeof value === 'object' && value?.unit?.symbol) {
            return `${value.amount} ${value.unit.symbol}`
        }
        return value
    };

    return sections.map((section, index) => {
        return (
            <table
                key={index}
                className={cx(
                    'table table-striped table-striped-alt table-hover table-article-info',
                    {
                        'table-articolo-info-multiple': isRepeatable,
                    }
                )}
            >
                <tbody>
                {getFilteredAttrs(section).map((attr, i) => {
                    // const rawValue = renderAttrValue(attr);
                    const value = getDisplayValue(attr.value)

                    // Debug log
                    // console.warn('value from RenderMisureList => ', value);

                    if (value === null || isUndefined(value) || value === '') {
                        return null;
                    }

                    return (
                        <tr key={i}>
                            <td>
                                {attr.label}
                                {isRepeatable && i === 0 ? ` ${index + 1}` : ''}
                            </td>
                            <td>
                                <strong dangerouslySetInnerHTML={{ __html: value }} />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    });
};

export default RenderMisureList;
