import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import get from 'lodash/get';

import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import SectorsTree from '../containers/SectorsTree';
import { actions } from '../actions/filtersActions';
import * as constants from '../constants';
import { getOptionLabelLocalized, getLocaleFromLanguageCode } from '../intl-helpers';
import AttributeNumericWrapper from './AttributeNumericWrapper';

function getListiniLabel(language) {
    return function (option) {
        const baseLabel = getOptionLabelLocalized(language)(option);

        if (option.extra && option.extra.codice_revisione !== '00') {
            const locale = getLocaleFromLanguageCode(language);
            const descrizione = get(option.extra, `descrizione_revisione.${locale}`, option.extra.codice_revisione);
            return `${baseLabel} - ${descrizione}`;
        }

        return baseLabel;
    };
}

export class Sidebar extends Component {
    render() {
        const {
            i18n,
            classificationFilter,
            brandFilter,
            isBrandFixed,
            statusFilter,
            priceRangeFilter,
            fuoriCartaceoFilter,
            esportabilePerCatalogoFilter,
            customFilter1,
            customFilter2,
            customFilter3,
            classeProdottiScontoVendita,
            classeProdotto,
            tipoArticolo,
            categoriaMerceologica,
            height,
            language,
            showPriceRangeFilter,
            lineFilter,
            modelFilter,
            seriesFilter,
            listiniFilter,
            productClassFilter,
            validFilterOptions,
            enableEsportabilePerCatalogoFilter,
        } = this.props

        // Mappa dei filtri da rendere dinamicamente
        const filters = [
            {
                key: 'customFilter1',
                condition: customFilter1.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={customFilter1}
                        placeholder={i18n._('filter:custom_filter1')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CUSTOM_FILTERS1, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'customFilter2',
                condition: customFilter2.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={customFilter2}
                        // placeholder={i18n._('filter:status')}
                        placeholder={i18n._('filter:custom_filter2')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CUSTOM_FILTERS2, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'customFilter3',
                condition: customFilter3.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={customFilter3}
                        placeholder={i18n._('filter:custom_filter3')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CUSTOM_FILTERS3, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'brandFilter',
                condition: !isBrandFixed,
                component: (
                    <DropdownListFilterWrapper
                        filter={brandFilter}
                        placeholder={i18n._('filter:brand')}
                        disabled={isBrandFixed}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={
                            // (item) => {
                            //     this.props.dispatch(actions.changeBrandFilterValue(item))
                            // }
                            (item) => {
                                this.props.onFilterChange('brand', item)
                            }

                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'statusFilter',
                condition: statusFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={statusFilter}
                        placeholder={i18n._('filter:status')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.STATUS_FILTER, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'fuoriCartaceoFilter',
                condition: fuoriCartaceoFilter && this.props.haveFuoriListinoCartaceo === true,
                component: (
                    <DropdownListFilterWrapper
                        filter={fuoriCartaceoFilter}
                        placeholder={i18n._('filter:fuoriCartaceo')}
                        validFilterOptions={null}
                        itemToString={(option) => i18n._(get(option, 'label'))}
                        //onChange={(item) => this.props.dispatch(actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, item))}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.FUORI_CARTACEO_FILTER, item)
                        }
                    />
                ),
            },
            {
                key: 'seriesFilter',
                condition: seriesFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={seriesFilter}
                        placeholder={i18n._('filter:series')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.SERIES_FILTER, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'lineFilter',
                condition: lineFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={lineFilter}
                        placeholder={i18n._('filter:line')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.LINE_FILTER, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'modelFilter',
                condition: modelFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={modelFilter}
                        placeholder={i18n._('filter:model')}
                        useVirtualList={false}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.MODEL_FILTER, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'listiniFilter',
                condition: listiniFilter.options.count() > 0 && (isBrandFixed || brandFilter.value.count() === 1),
                component: (
                    <DropdownListFilterWrapper
                        filter={listiniFilter}
                        placeholder={i18n._('filter:listini')}
                        useVirtualList={false}
                        itemToString={getListiniLabel(language)}
                        itemToSortString={(o) => `${o.extra.codice_listino}_${o.extra.codice_revisione}`}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.LISTINI_FILTER, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'tipoArticolo',
                condition: tipoArticolo.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={tipoArticolo}
                        // placeholder={i18n._('filter:status')}
                        placeholder={i18n._('filter:tipo_articolo')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.TIPO_ARTICOLO, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'classeProdottiScontoVendita',
                condition: classeProdottiScontoVendita.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={classeProdottiScontoVendita}
                        placeholder={i18n._('filter:classe_categoria_sconto_vendita')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CLASSE_CATEGORIA_SCONTO_VENDITA, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'classeProdotto',
                condition: classeProdotto.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={classeProdotto}
                        placeholder={i18n._('filter:classe_prodotto')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CLASSE_PRODOTTO, item)
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'categoriaMerceologica',
                condition: categoriaMerceologica.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={categoriaMerceologica}
                        placeholder={i18n._('filter:categoria_merceologica')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onFilterChange(constants.CATEGORIA_MERCEOLOGICA, item)
                        }
                        multiple={true}
                    />
                ),
            },
        ];

        return (
            <div>
                {/* Renderizzazione dinamica dei filtri */}
                {filters.map(({ key, condition, component }) => condition && <Fragment key={key}>{component}</Fragment>)}

                {/* Filtro per intervallo di prezzo */}
                {showPriceRangeFilter && (
                    <Fragment>
                        <div className="divider" />
                        <AttributeNumericWrapper
                            label={i18n._('priceRange')}
                            rangeSuffix="€"
                            freeStep={true}
                            min={priceRangeFilter.min}
                            max={priceRangeFilter.max}
                            start={priceRangeFilter.value[0]}
                            end={priceRangeFilter.value[1]}
                            isActive={priceRangeFilter.value[0] !== 0 || priceRangeFilter.value[1] !== 0}
                            attribute={{}}
                            onLoad={() => {}}
                            onClear={() => this.props.dispatch(actions.setFilterValue(constants.PRICE_RANGE_FILTER, [0, 0]))}
                            onChange={(_, range) => this.props.dispatch(actions.setFilterValue(constants.PRICE_RANGE_FILTER, range))}
                        />
                    </Fragment>
                )}

                <div className="divider" />
                <h6 className="text-uppercase text-primary">
                    <Trans id="choose:by:taxonomy" />
                </h6>
                <DropdownListFilterWrapper
                    filter={classificationFilter}
                    placeholder={i18n._('filter:classification')}
                    clearable={false}
                    itemToString={getOptionLabelLocalized(language)}
                    // onChange={(item) => this.props.dispatch(sectorsActions.setClassificationValue(item))}
                    onChange={(item) =>
                        this.props.onFilterChange('classification', item)
                    }
                    useVirtualList={false}
                />
                {classificationFilter.value?.code === 'etim' && (
                    <>
                        <h6 className="text-uppercase text-primary">Classe prodotto</h6>
                        <DropdownListFilterWrapper
                            filter={productClassFilter}
                            validFilterOptions={validFilterOptions}
                            placeholder={i18n._('filter:product:class')}
                            itemToString={(option) =>
                                option ? `${option.code ? option.code : '-'} ${getOptionLabelLocalized(language)(option)}` : ''
                            }
                            itemToSortString={getOptionLabelLocalized(language)}
                            onChange={(item) =>
                                this.props.onFilterChange('etim_class', item)
                            }
                            useVirtualList={false}
                        />
                    </>
                )}
                <SectorsTree
                    sidebarHeight={height}
                    classificationType={classificationFilter.value?.code}
                    onChange={this.props.onFilterChange}
                />
            </div>
        );
    }
}

Sidebar.propTypes = {
    classificationFilter: PropTypes.object.isRequired,
    brandFilter: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    isBrandFixed: PropTypes.bool,
    statusFilter: PropTypes.object.isRequired,
    showPriceRangeFilter: PropTypes.bool.isRequired,
    //selectedItem: PropTypes.string,
    height: PropTypes.number,
    language: PropTypes.string,
    validFilterOptions: PropTypes.object,
};

export default withI18n()(Sidebar);
