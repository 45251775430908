import React, { Component } from 'react';
import { connect } from 'react-redux';

import UsersList from '../components/UsersList';
import { actions } from '../actions/usersActions';
import { selectors } from '../reducers/usersReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { getFromInternalPage, getLoadedFromOtherPages } from '../reducers/pageStateReducer'
import { setIsLoadingFromLocalStorage, setLoadedFromOthersPages } from '../actions/pageStateActions';

export class UsersListContainer extends Component {
    componentDidMount() {
        this.props.dispatch(setIsLoadingFromLocalStorage(false))
    }
    componentWillUnmount(){
        this.props.dispatch(setLoadedFromOthersPages(true))
    }
    render() {
        return <UsersList {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        isFetching: selectors.getIsFetching(state),
        users: selectors.getUsers(state),
        total: selectors.getUsersTotal(state),
        // q: filtersSelectors.getFilter(state, constants.FULL_TEXT_FILTER).value,
        sorting: selectors.getSorting(state),
        currentPage: selectors.getCurrentPage(state),
        pages: selectors.getPagesTotal(state),
        filters: selectors.getFilters(state),
        userGroups: appSelectors.getUserGroups(state),
        fromInternalPage: getFromInternalPage(state),
        loadedFromOthersPages: getLoadedFromOtherPages(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sortBy(attr) {
            dispatch(actions.sortBy(attr));
        },
        toggleLock(id) {
            dispatch(actions.toggleLockUser(id));
        },
        enableUser(id) {
            dispatch(actions.enableUser(id));
        },
        deleteUser(id) {
            dispatch(actions.deleteUser(id));
        },
        onPageChange(page) {
            dispatch(actions.changePage(page));
        },
        resetFilters() {
            dispatch(actions.resetUsersFilters());
        },
        updateUserData(id, data) {
            dispatch(actions.updateUserData(id, data));
        },
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListContainer);
