import { put } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { actions, setFilterValue } from '../../actions/filtersActions';
import { removeFilterFromLocalStorage } from '../../utils/LocalStorageFilterUtils';

function* loadPriceRangeFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadPriceRangeFilters')
    const storedPriceRangeFrom = storedFilters.hasOwnProperty('prezzo_listino_from')
    const storedPriceRangeTo = storedFilters.hasOwnProperty('prezzo_listino_to')

    if(storedPriceRangeFrom === undefined || storedPriceRangeTo === undefined) {
        emitSagaEvent('saga_end')
        console.log('saga_end from loadPriceRangeFilters')
        return
    }
    const priceRangeFrom = storedFilters.prezzo_listino_from
    const priceRangeTo = storedFilters.prezzo_listino_to
    const priceRange = [
        {
            min: priceRangeFrom,
            max: priceRangeTo,
        }]
    yield put(actions.setPriceRange(priceRange))

    emitSagaEvent('saga_end')
    console.log('saga_end from loadPriceRangeFilters')
}

function* removePriceRangeFilter(key){
    removeFilterFromLocalStorage('prezzo_listino_from')
    removeFilterFromLocalStorage('prezzo_listino_to')

    // yield put(setFilterValue(key, [0,0]))
}
export { loadPriceRangeFilters, removePriceRangeFilter }