import React from 'react'
import { desideredOrder, sortChildrenProperties } from './IntegrationsUtils';

const PrezzoCalcolatoTableHeader = ({ prezzoCalcolatoHeading }) => {

    // console.table(prezzoCalcolatoHeading)
    const prezziCalcolatiHeadingSorted = sortChildrenProperties(prezzoCalcolatoHeading, desideredOrder)

    const prezziCalcolatiHeadingSortedFiltered = prezziCalcolatiHeadingSorted.filter(
        item => desideredOrder.includes(item.property)
    )

    return (
        <tr>
            {
                prezziCalcolatiHeadingSortedFiltered.map((item, index) =>
                        //item.value > 0 ? (
                        <th
                            key={index}
                            className="whitespace-nowrap table-header-sortable c-hand text-right"
                            scope="row"
                        >
                            {item.label['it_IT']}
                        </th>
                    //) : null
                )
            }
        </tr>
    )
}

export default PrezzoCalcolatoTableHeader