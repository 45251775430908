import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
// import _ from 'lodash';
import includes from 'lodash/includes';

import appReducer from './appReducer';
import collectionsReducer from './collectionsReducer';
import filtersReducer from './filtersReducer';
// import itemDataCategoriesReducer from './itemDataCategoriesReducer';
import itemsReducer from './itemsReducer';
import productClassesReducer from './productClassesReducer';
import sectorsReducer from './sectorsReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import { actionTypes as appActionTypes } from '../actions/appActions';
import { actionTypes as userActionTypes } from '../actions/userActions';

import pageStateReducer from './pageStateReducer';
import localStorageReducer from './localStorageReducer';

const dontClearKeys = [
    /*'user', 'app'*/
];

const clearOn = (type, reducers) => {
    const state = Object.keys(reducers).reduce((result, key) => {
        result[key] = (state, action) => {
            return action && action.type === type && !includes(dontClearKeys, key)
                ? reducers[key](undefined, {}) //reinitialize
                : reducers[key](state, action); // pass through
        };

        return result;
    }, {});

    return state;
};

const applicationReducer = combineReducers(
    clearOn(userActionTypes.AUTH_LOGOUT, {
        app: appReducer,
        collections: collectionsReducer,
        filters: filtersReducer,
        // itemDataCategories: itemDataCategoriesReducer,
        items: itemsReducer,
        loadingBar: loadingBarReducer,
        localStorage: localStorageReducer,
        productClasses: productClassesReducer,
        sectors: sectorsReducer,
        user: userReducer,
        users: usersReducer,
        pageState: pageStateReducer,
    })
);

let bootstrappedAppState = {};

const rootReducer = (state, action) => {
    // Listen a new action that bring the default app state
    if (action.type === appActionTypes.SET_BOOSTRAPPED_STATE){
        // Save the default app state in boostrappedAppState
        bootstrappedAppState = action.payload

        // debug log
        //console.warn('bootstrappedAppState before fetch data from local storage => ', bootstrappedAppState)
    }

    if (action.type === appActionTypes.BOOTSTRAP_APP_SUCCESS) {
        // Store the state after bootstrap app success only if bootstrappedAppState is empty. This meaning the loading of default values have failed.
        if(Object.keys(bootstrappedAppState).length === 0) {
            bootstrappedAppState = {
                filters: state.filters,
                productClasses: state.productClasses,
                // TODO: trovare modo di resettare il componente dell'albero, visto che i dati non cambiano non si ri-renderizza
                // probabilmente necessario fare qualcosa quando viene dispatchata l'azione RESTORE_BOOTSTRAPPED_STATE?
                sectors: state.sectors
            };
        }
    }

    if (action.type === appActionTypes.RESTORE_BOOTSTRAPPED_STATE) {

        // console.warn('called RESTORE_BOOTSTRAPPED_STATE', bootstrappedAppState)
        //
        // console.warn('filters => ', bootstrappedAppState?.filters?.toJS())
        // console.warn('productClasses => ', bootstrappedAppState?.productClasses?.toJS())
        // console.warn('sectors => ', bootstrappedAppState?.sectors?.toJS())

        const { user, app, collections, sectors, pageState } = state;

        state = {
            user,
            app,
            collections,
            pageState,
            ...bootstrappedAppState
        };

        if (action.payload && action.payload.restoreSectors === false) {
            state.sectors = sectors;
        }
    }

    // if(action.type === appActionTypes.INITIALIZE_BOOTSTRAPPED_STATE_SUCCESS) {
    //
    //     // Do something
    //
    // }

    return applicationReducer(state, action);
};

export default rootReducer;
