import { call, put } from 'redux-saga/effects'
import * as constants from '../../../constants'
import { setClassificationFilters } from '../classificationFilterSaga';

// Implement a strategy pattern to execute the right method to handle the single filter removal
const brandRelatedFilters = ['status', 'line', 'model', 'series', 'listini']
const savingStrategies = {
    ...Object.fromEntries(
        brandRelatedFilters.map((filter) => [
            filter,
            function* (storedFilters, filter, key, value) {
            },
        ])
    ),
    [constants.FUORI_CARTACEO_FILTER]: function* (storedFilters, filter, key, value) {

    },
    classification: function* (storedFilters, filter, key, value) {
        yield call(setClassificationFilters, filter, value)
    },
    sector: function* (_storedFilters, filter, key, value) {

    },
    brand: function* (_storedFilters, filter, _key, value){
        //yield put(changeBrandFilterValue({ value }))

    },
    priceRange: function* (_storedFilters, filter, key, _value) {

    },
    fullText: function* (_storedFilters, filter, key, _value){

    },
    productClass: function* (_storedFilters, filter, _key, _value) {
        // Clear the etim_class from Storage.
        // Note: productClass filter is the etim_class payload.
        // this function is from etimFilterSaga

    },
    [constants.REPLACEMENTS_FILTER]: function* (storedFilters, filter, key, _value) {

    },
    [constants.OPTIONALS_FILTER]: function* (storedFilters, filter, key, _value) {

    },
    // [constants.FUORI_CARTACEO_FILTER]: function* (storedFilters, filter, key, _value) {
    //     yield call(removeGenericFilter, storedFilters, key, null);
    // },
    [constants.ESPORTABILE_PER_CATALOGO_FILTER]: function* (storedFilters, filter, key, _value) {
    },
    default: (storedFilters, key) => {
        console.warn('default called')
    },
}

export {
    savingStrategies,
}