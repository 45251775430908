import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { includes, xor } from 'lodash';
import clsx from 'classnames';

import DropdownMultiple from '../utils/DropdownMultiple';
import FieldHoc from './FieldHoc';
import { fetchBrands } from '../../api';

export function SelectBrandsMultiple({
    storedValue,
    value,
    onChange,
    onCustomChange,
    label,
    labelClass,
    inputClassName,
    inputWrapperClass = '',
    valueKey = 'id',
}) {
    // FIXME: traduzioni
    // FIXME: passare locale dinamicamente
    const { isLoading, data } = useQuery(
        [
            'brands',
            {
                locale: 'it_IT',
                page: 1,
                per_page: 999,
            },
        ],
        fetchBrands
    );

    const [selectedBrandsId, setSelectedBrandsId] = React.useState(value);

    useEffect(() => {
        if (value === '') {
            setSelectedBrandsId([]);
            onChange([]);
        }

        //console.warn('value in SelectBrandsMultiple => ', storedValue)
    }, [value]);

    function onSelect(selectedItem) {
        const newIds = xor(selectedBrandsId, [selectedItem[valueKey]]);

        setSelectedBrandsId(newIds)
        onChange(newIds)
        // Pass brand to onCustomChange to update Parent ExportPanel State - in future we can handle with separate redux state
        if(typeof onCustomChange === 'function') {
            onCustomChange(newIds)
        }

    }

    function onRemove(brandId) {
        const newIds = xor(selectedBrandsId, [brandId]);

        //console.warn('onRemove brands => ',  newIds)
        setSelectedBrandsId(newIds)
        onChange(newIds)
        // Pass brand to onCustomChange to update Parent ExportPanel State - in future we can handle with separate redux state
        if(typeof onCustomChange === 'function') {
            onCustomChange(newIds)
        }
    }

    const items = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.data.map((item) => {
            return {
                ...item,
                value: item[valueKey],
            };
        });
    }, [data, valueKey]);

    // const selectedBrands = items.filter((item) => {
    //     return includes(selectedBrandsId, item[valueKey]);
    // });

    const selectedBrands = React.useMemo(() => {
        if (storedValue === undefined) {
            return items.filter((item) => includes(selectedBrandsId, item[valueKey]))
        }

        if(!storedValue || !items) {
            return []
        }

        return items.filter((item) => includes(storedValue, item[valueKey]));
    }, [storedValue, items, selectedBrandsId, valueKey])

    return (
        <div className="form-group">
            <label className={clsx('form-label', labelClass)}>{label}</label>
            <div className={inputWrapperClass}>
                <DropdownMultiple
                    placeholder={label}
                    isLoading={isLoading}
                    itemToString={(item) => {
                        if (item && item.description) {
                            return `${item.description.it_IT} (${item.code})`;
                        }

                        return '-';
                    }}
                    items={items}
                    itemValueKey={valueKey}
                    selectedItem={selectedBrands}
                    onLoad={() => {}}
                    onChange={onSelect}
                    onCustomChange={onCustomChange}
                    onClear={() => {
                        setSelectedBrandsId([]);
                        onChange([]);
                    }}
                    onRemove={onSelect}
                    inputClassName={inputClassName}
                />
                <div>
                    {selectedBrands.map((brand) => {
                        return (
                            <span
                                className="label label-secondary"
                                key={brand.id}
                                style={{ marginRight: '5px', marginTop: '2px' }}
                            >
                                {brand.description.it_IT} ({brand.code})
                                <i
                                    onClick={() => onRemove(brand[valueKey])}
                                    className="icon icon-cross c-hand"
                                ></i>
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export function SelectBrandsMultipleFieldComponent({
    input,
    meta,
    placeholder,
    className,
    options,
    ...rest
}) {
    return (
        <SelectBrandsMultiple
            inputClassName={clsx('form-input input-lg')}
            label="Seleziona marche"
            {...input}
            {...rest}
        />
    );
}

export const SelectBrandsMultipleField = FieldHoc(SelectBrandsMultipleFieldComponent);
