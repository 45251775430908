import React, { Fragment, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { useQuery } from 'react-query';
// import { useTable, useFlexLayout } from 'react-table';
import { withI18n, Trans } from '@lingui/react';
import { DataTable } from '@ingruz/tabulisk';
import { Portal } from 'react-portal';
import cx from 'classnames';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { getDetailValue } from '../utils/dataFormaters';
import { selectors } from '../reducers/appReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import CollectionControlsHeader from './CollectionControlsHeader';
import Loader from './utils/Loader';
import ArticleDetailsModal from './ArticleDetailsModal';
import { AddToCollectionBtn } from './AddToCollectionModal';
import { DeleteArticleBtn } from './DeleteArticleBtn';
import { getApiPayload } from '../selectors/filterPayload';
// import ArticleDetails from '../containers/ArticleDetails';
import * as filterSelectors from '../selectors/filterSelectors'
import { getCurrencySymbol } from './ArticleComponents/ArticleRecap/ArticleRecapUtils';
function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

const staticColumnWidthMap = {
    codice_articolo: 200,
    linea: 200,
    modello: 200,
    serie: 200,
    stato: 200,
    sku: 110,
};

function getColumnWidth(propertyCode, properties, locale) {
    if (staticColumnWidthMap[propertyCode]) {
        return staticColumnWidthMap[propertyCode];
    }

    if (properties) {
        let label = get(properties, `label[${locale}]`);

        if (!label) {
            label = get(properties, `label[it]`);
        }

        if (label) {
            return label.length * 12 + 10;
        }
    }

    return 200;
}

function buildTableColumns(
    choosenProperties,
    productProperties,
    locale,
    i18n,
    selectable = false,
    onSelectAll,
    onRemoveAll,
    selectAllChecked,
    isAnyFilterActive,
    onSelectRow,
    showLists,
    priceDecimalPrecision,
    deleteProductsGrant,
    dispatch
) {
    // console.log(priceDecimalPrecision);

    const priceProperties = [
        'prezzo_listino',
        'prezzo_listino_netto',
        'prezzo_listino_rivenditore',
        'prezzo_dettaglio_raccomandato',
        'prezzo_articolo_pallettizzato',
        'prezzo_precedente',
    ];

    // console.warn(choosenProperties);

    const baseColumns = choosenProperties
        .filter(
            (propertyCode) => !['custom_classifications', 'categorie_pim'].includes(propertyCode)
        )
        .map((propertyCode) => {
            return {
                Header: () => {
                    const label = productProperties[propertyCode]
                        ? productProperties[propertyCode].label[locale] ||
                          productProperties[propertyCode].label['it_IT']
                        : 'N.d.';

                    return <div>{label}</div>;
                },
                align: includes(priceProperties, propertyCode) ? 'right' : 'left',
                accessor: propertyCode,
                width: getColumnWidth(propertyCode, productProperties[propertyCode], locale),
                disableSortBy: !includes(
                    [
                        'codice_articolo',
                        'descrizione_articolo',
                        'descrizione_marca',
                        'prezzo_listino',
                    ],
                    propertyCode
                ),
                Cell: ({ cell, row }) => {
                    let cellStyle = {
                        // whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    };

                    if (includes(priceProperties, propertyCode)) {
                        cellStyle = {
                            ...cellStyle,
                            flexGrow: 1,
                            textAlign: 'right',
                        };
                    }

                    // Currency code: the currency code is in the property valuta
                    // valuta.value.code
                    //console.warn('row.original => ', row.original?.valuta?.value?.code)
                    //console.warn('cell => ', cell)
                    const currencyCode = row.original?.valuta?.value?.code ? row.original?.valuta?.value?.code : ''


                    let cellValue = cell.value;
                    let propertyProperties = productProperties[propertyCode];

                    if (propertyCode === 'custom_classification') {
                        cellValue = get(row.original, 'custom_classifications');
                        propertyProperties = productProperties['custom_classifications'];
                    }

                    if (propertyCode === 'categoria_pim') {
                        cellValue = get(row.original, 'categorie_pim');
                        propertyProperties = productProperties['categorie_pim'];
                    }

                    let value = getDetailValue(
                        cellValue,
                        propertyProperties,
                        locale,
                        productProperties,
                        i18n,
                        priceDecimalPrecision
                    );

                    if (includes(priceProperties, propertyCode) && value !== null) {
                        // const currencySymbol = currencyCode
                        //     ? currenciesCodeISO[currencyCode]?.symbol || "€"
                        //     : "€"

                        const currencySymbol = getCurrencySymbol(row.original?.valuta ? row.original.valuta : '' )
                        value = `${currencySymbol} ${value}`;
                    }

                    return (
                        <div
                            style={cellStyle}
                            className={cx({
                                'text-error blink':
                                    propertyCode === 'stato' &&
                                    get(cell, 'value.value.code') === 'IV006650',
                            })}
                            dangerouslySetInnerHTML={{
                                __html: value,
                            }}
                        />
                    );
                },
            };
        });

    if (!showLists && !deleteProductsGrant) {
        return baseColumns;
    }

    baseColumns.unshift({
        Header: () => {
            return (
                <span style={{ paddingLeft: '4px' }}>
                    <CollectionControlsHeader
                        selectAllDisabled={isAnyFilterActive === false}
                        selectAllChecked={selectAllChecked}
                        onSelectAll={onSelectAll}
                        onRemoveAll={onRemoveAll}
                    />
                </span>
            );
        },
        width: 90,
        disableSortBy: true,
        accessor: 'id',
        Cell: ({ row }) => {
            // console.log(row);
            return (
                <div className="flex items-center ml-1 pt-0.5" onClick={(e) => e.stopPropagation()}>
                    <label
                        className="checkmark-container"
                        onClick={(e) => {
                            e.stopPropagation();
                            onSelectRow(row.original.id);
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={row.original.isSelected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {}}
                        />
                        <span className="checkmark"></span>
                    </label>
                    {/* <span className="form-group">
                        <label
                            className="form-checkbox form-checkbox-inline"
                            style={{ top: 0, paddingRight: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelectRow(row.original.id);
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={row.original.in_collection}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => {}}
                            />
                            <i className="form-icon" />
                        </label>
                    </span> */}
                    {showLists && <AddToCollectionBtn itemId={row.original.id} />}
                    {deleteProductsGrant && <DeleteArticleBtn code={row.original.codice_articolo.value} id={row.original.id} />}
                </div>
            );
        },
    });

    return baseColumns;
}

export function DynamicTable({
    productProperties,
    items,
    isFetching,
    // apyPayload,
    i18n,
    tableColumns,
    isAnyFilterActive,
    selectable,
    sortBy,
    sorting,
    onSelectAll,
    onRemoveAll,
    onSelectRow,
    selectAllChecked,
    showLists,
    priceDecimalPrecision,
    deleteProductsGrant,
    dispatch
    // ...rest
    // width
}) {
    // console.warn(items)
    // const payload = {
    //     per_page: 40,
    //     page: 1,
    //     locale: 'it_IT',
    //     marca: '5f96f4eb-e841-41f2-85cf-3eb857def25a',
    //     product_tree: 'idrolab'
    // };

    /*const properties = [
        'sigla_marca',
        'codice_articolo',
        'descrizione_articolo',
        'unita_ordine',
        'classe_prodotto'
    ];*/

    const language = i18n._language;

    const locale = getLocaleFromLanguageCode(language);

    // console.log(locale);
    // console.log(productProperties);

    const columns = React.useMemo(
        () =>
            buildTableColumns(
                tableColumns,
                productProperties,
                locale,
                i18n,
                selectable,
                onSelectAll,
                onRemoveAll,
                selectAllChecked,
                isAnyFilterActive,
                onSelectRow,
                showLists,
                priceDecimalPrecision,
                deleteProductsGrant,
                dispatch
            ),
        [
            tableColumns,
            locale,
            productProperties,
            i18n,
            selectable,
            onSelectAll,
            onRemoveAll,
            selectAllChecked,
            isAnyFilterActive,
            onSelectRow,
            showLists,
            priceDecimalPrecision,
            deleteProductsGrant,
            dispatch
        ]
    );

    return (
        <Table
            columns={columns}
            data={items}
            isFetching={isFetching}
            sortBy={sortBy}
            sorting={sorting}
        />
    );
}

function Table({ columns, data = [], isFetching, sortBy, sorting }) {
    // Use the state and functions returned from useTable to build your UI

    // console.log('data from Table => ', data)

    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    React.useEffect(() => {
        const tBody = document.getElementsByClassName('--tabulisk-tbody')[0];
        const tHead = document.getElementsByClassName('--tabulisk-thead')[0];

        const handleScroll = () => {
            const left = tBody.scrollLeft;

            tHead.scrollLeft = left;
        };

        if (tBody) {
            tBody.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tBody) {
                tBody.removeEventListener('scroll', handleScroll);
            }
        };
    });


    return (
        <Fragment>
            {showModal && (
                <Portal>
                    <ArticleDetailsModal
                        article={{ id: currentItem }}
                        onClose={() => {
                            setShowModal(false);
                            setCurrentItem(null);
                        }}
                        containerId={`article-detail-modal-${currentItem}`}
                    />
                </Portal>
            )}
            <DataTable
                data={isFetching ? [] : data}
                columns={columns}
                config={{
                    useFlex: true,
                    remote: true,
                    paginate: false,
                    onToggleSortBy: (columnId) => {
                        // console.log(columnId);
                        sortBy(columnId);
                    },
                    // useExpanded: true,
                    // renderRowSubComponent,
                    sortableColumnTitle: null,
                    getRowProps(row) {
                        // console.log(row);

                        return {
                            className: isOdd(row.index + 1)
                                ? 'c-zoom-in'
                                : 'bg-alternate-row c-zoom-in',
                            onClick: () => {
                                // console.log(row);
                                setShowModal(true);
                                setCurrentItem(row.original.id);
                            },
                        };
                    },
                    controlledStateHook: (tableState) => {
                        /* eslint-disable-next-line */
                        const sortBy = React.useMemo(
                            () => [{ id: sorting.sortBy, desc: sorting.sortDirection === 'desc' }],
                            /* eslint-disable-next-line */
                            [sorting]
                        );

                        // console.log(tableState);

                        /* eslint-disable-next-line */
                        return React.useMemo(() => {
                            return {
                                ...tableState,
                                sortBy,
                            };
                            /* eslint-disable-next-line */
                        }, [tableState, sortBy]);
                    },
                    noRowsMessage: isFetching ? (
                        <Loader />
                    ) : (
                        <div className="bg-gray">
                            <div className="empty">
                                <div className="empty-icon">
                                    <i className="icon icon-search" />
                                </div>
                                <p className="empty-title h5">
                                    <Trans id="article:count" values={{ count: 0 }} />
                                </p>
                                <p className="empty-subtitle">
                                    <Trans id="Use filters to re-search" />
                                </p>
                            </div>
                        </div>
                    ),
                }}
            />
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        productProperties: selectors.getProductProperties(state),
        apyPayload: getApiPayload(state),
        tableColumns: selectors.getTableColumns(state),
        isAnyFilterActive: filterSelectors.isAnyFilterActive(state),
        deleteProductsGrant: userSelectors.getUserGrant(state, 'delete_products')
    };
}

export default compose(connect(mapStateToProps), withI18n())(DynamicTable);
