import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductClassFilterWrapper from '../components/ProductClassFilterWrapper';
import { selectors } from '../reducers/productClassesReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as filterSelectors from '../selectors/filterSelectors'
// import { selectors as collectionsSelectors } from '../reducers/collectionsReducer';
import { actions } from '../actions/productClassesActions';
import { actions as filtersActions } from '../actions/filtersActions';
import { fetchItems } from '../actions/itemsActions';
import { ETIM_CLASSIFICATION } from '../constants';
import { isAnyFilterActive } from '../selectors/filterSelectors';

export class ProductClassFilterContainer extends Component {
    render() {
        return <ProductClassFilterWrapper {...this.props} />;
    }
}

function getValidFilterOptions(node) {
    if (node === null) {
        return null;
    }

    if (node.children.size === 0) {
        return null;
    }

    return node.children.map((l) => l.id);
}

function mapStateToProps(state) {
    const classificationType = sectorsSelectors.getFilterValue(state);
    let selectedNode = null;

    if (classificationType === ETIM_CLASSIFICATION) {
        selectedNode = sectorsSelectors.getSelectedTreeItemRecord(state);
    }

    return {
        isFetching: selectors.getIsFetching(state),
        isAnyFilterActive: isAnyFilterActive(state),
        activeFilters: selectors.getProductClassAttributesActiveFilters(state),
        globalActiveFilters: filterSelectors.getActiveFiltersDetails(state),
        attributes: selectors.getProductClassAttributesList(state),
        language: appSelectors.getLanguage(state),
        productClassFilter: selectors.getFilter(state),
        validFilterOptions: getValidFilterOptions(selectedNode),
        enableExportPanel: appSelectors.getEnableExportPanel(state),
        hasOnlyBrandFilter: filterSelectors.hasOnlyBrandFilter(state),
        // collectionShownId: collectionsSelectors.getShownCollection(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onAttributeResetClick: (key) => {
            // console.warn('CALLED onAttributeResetClick for the etim attributes of type ATTRIBUTE_TYPE_NUMERIC')
            dispatch(actions.resetProductClassAttribute(key));
        },
        onCheckboxToggle: (attribute, key) => {
            // console.warn('CALLED onCheckboxToggle for the etim attributes of type ATTRIBUTE_TYPE_ALPHANUMERIC e DEFAULT')
            dispatch(actions.toggleProductClassAttributeFilter(attribute, key));
        },
        onSetProductClassAttributeLogic: (attribute, key) => {
            // console.warn('CALLED onSetProductClassAttributeLogic for the etim attributes of type ATTRIBUTE_TYPE_LOGIC')
            dispatch(actions.setProductClassAttributeLogic(attribute, key));
        },
        onSetProductClassAttributeNumeric: (attribute, range) => {
            // console.warn('CALLED onSetProductClassAttributeNumeric for the etim attributes of type ATTRIBUTE_TYPE_NUMERIC')
            dispatch(actions.setProductClassAttributeNumeric(attribute, range));
        },
        resetProductClassAttributeAll() {
            // console.warn('CALLED resetProductClassAttributeAll')
            dispatch(actions.resetProductClassAttributeAll());
        },
        fetchProductClassAttributeData(id) {
            // console.warn('CALLED fetchProductClassAttributeData')
            dispatch(actions.fetchProductClassAttributeData(id));
        },
        fetchItems() {
            // console.warn('CALLED fetchItems')
            dispatch(fetchItems());
        },
        resetBrandFilter() {
            // console.warn('CALLED resetBrandFilter')
            dispatch(filtersActions.changeBrandFilterValue(null));
        },
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductClassFilterContainer);
