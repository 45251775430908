import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { intersection, isNaN } from 'lodash';

import { actionTypes, actions } from '../actions/appActions';
import {
    setFilterOptions,
} from '../actions/filtersActions';
import { selectors as userSelectors } from '../reducers/userReducer';
import { setProductClassOptions, setProductClassValue } from '../actions/productClassesActions';
import { setTree, initProductTreeRoots } from '../actions/sectorsActions';
import { fetchCollections } from '../actions/collectionsActions';
import { removeSplashScreenLoader } from '../helpers';
import {
    fetchBrandFilter,
    fetchClassificationTree,
    fetchEtimClassFilter,
    // getProductInfoMap,
    getAllProductProperties,
    // fetchAnagrafica,
    fetchProductTreeRootsFilter,
    getUserGroups,
    fetchPriceRangeFilter,
    fetchPreferences,
} from '../api';
import * as constants from '../constants';
import { selectors } from '../reducers/appReducer';
import { getApiPayload } from '../selectors/filterPayload';
import { setLocalStorageFiltersSuccess } from '../actions/localstorageActions';
import { getStoredFilters } from '../utils/LocalStorageFilterUtils';
import { resetProductClassSaga } from './productClassesSaga';
import { setIsChangingClassification } from '../actions/pageStateActions';
import { loadCustomFiltersOptions } from './filtersSaga/customFiltersSaga';

export function* bootstrapApp() {
    try {
        const user = yield select(userSelectors.getUser);

        yield put(actions.bootstrapAppStart());

        // As first operation, call initializeBootstrappedState to get and set the default state value
        // console.warn('CALLED BOOTSTRAP APP!')
        yield call(initializeBootstrappedState)

        // Get filters from localStorage and put into the related state
        const storedFilters = getStoredFilters()
        if (storedFilters) {
            // Dispatch an action to update Redux state with the localStorage stored filters
            yield put(setLocalStorageFiltersSuccess(storedFilters))
        }

        const productTreeRootsRes = yield call(fetchProductTreeRootsFilter);

        yield put(initProductTreeRoots(productTreeRootsRes.data));

        // Get apiPayload from Redux state
        const apiPayload = yield select(getApiPayload)

        const calls = [
            call(fetchBrandFilter, apiPayload),
            call(fetchClassificationTree, apiPayload),
            call(fetchEtimClassFilter, apiPayload),
            call(fetchPriceRangeFilter, apiPayload),
            // call(getProductInfoMap),
            call(getAllProductProperties),
            // call(fetchAnagrafica),
        ];

        if (user.ruolo === 'admin') {
            calls.push(call(getUserGroups));
        }

        const [
            brandsRes,
            treeRes,
            etimClassRes,
            priceRangeRes,
            // productInfoMapRes,
            productPropertiesRes,
            // anagraficaRes,
            userGroupsRes,
        ] = yield all(calls);


        // console.log("brandRes from appSaga => ", brandsRes)
        yield put(setFilterOptions(constants.BRAND_FILTER, brandsRes.data))

        // yield put(setPriceRange(priceRangeRes.data));
        // yield put(actions.setProductInfoMap(productInfoMapRes.data));

        yield put(actions.setProductProperties(productPropertiesRes.data))

        yield put(setProductClassOptions(etimClassRes.data))
        yield put(setTree(treeRes.data))

        if (user.ruolo === 'admin') {
            yield put(actions.setUserGroups(userGroupsRes.data))
        } else {
            // Se non sono admin verifico che le colonne selezionate per la tabella personalizzata
            // siano tra quelle per cui il mio gruppo ha il permesso di visualizzazione
            const currentTableColumns = yield select(selectors.getTableColumns)

            if (currentTableColumns && currentTableColumns.length > 0) {
                const validProps = productPropertiesRes.data.map((p) => p.code)

                yield put(actions.setTableColumns(intersection(currentTableColumns, validProps)))
            }
        }

        yield put(fetchCollections())

        /*if (brandsRes.data.length === 1) {
            // if (brandsRes.data.length > 1) {
            const brand = brandsRes.data[0];

            yield put(
                setFixedBrand({
                    label: brand.nome,
                    value: brand.id,
                    code: brand.code,
                })
            );

            yield put(fetchItems({ bootstrap: true }));

            yield put(enableFilters(brandDependentFilters));
        } else {
            yield put(actions.bootstrapAppSuccess());
        }*/
        yield put(actions.bootstrapAppSuccess())
    } catch (err) {
        // TODO: gestire messaggio di errore
        yield put(actions.bootstrapAppFail('Unable to contact remote server'))
        console.error(err)
    }
}

function* handleLoadPreferences() {
    try {
        const preferencesRes = yield call(fetchPreferences)

        if (preferencesRes.data) {
            const appName = preferencesRes.data.filter(
                (preference) => preference.name === 'appName'
            )

            if (appName.length > 0 && appName[0].value !== '') {
                yield put(actions.setAppName(appName[0].value));
            }

            const appColor = preferencesRes.data.filter(
                (preference) => preference.name === 'appColor'
            )

            if (appColor.length > 0) {
                window.document.body.style.setProperty('--color-primary', appColor[0].value)
            }

            const alternateRowColor = preferencesRes.data.filter(
                (preference) => preference.name === 'alternateRowColor'
            )

            if (alternateRowColor.length > 0) {
                window.document.body.style.setProperty(
                    '--color-alternate-row',
                    alternateRowColor[0].value
                )
            }

            const priceDecimalPrecision = preferencesRes.data.filter(
                (preference) => preference.name === 'priceDecimalPrecision'
            )

            if (priceDecimalPrecision.length > 0 && !isNaN(parseInt(priceDecimalPrecision[0].value))) {
                yield put(
                    actions.setAppParameter(
                        'priceDecimalPrecision',
                        parseInt(priceDecimalPrecision[0].value)
                    )
                )
            }
        }
    } catch (err) {
        console.error(err)
    }
}

function hideLoadingSplashScreen() {
    removeSplashScreenLoader()
}

function* initializeBootstrappedState() {
    const user = yield select(userSelectors.getUser)

    yield call(resetProductClassSaga)
    // for now, I use setIsChangingClassification to prevent fetchItems to be executed
    yield put(setIsChangingClassification(true))
    yield put(setProductClassValue(null))

    // Get apiPayload from Redux state
    // const apiPayload = yield select(getApiPayload)
    const defaultApiPayload = {
        "per_page": 40,
        "page": 1,
        "include_accessori": true,
        "include_ricambi": true,
        "locale": "it_IT",
        "product_tree": "idrolab"
    }
    // console.warn('apiPayload => ', apiPayload)
    // console.warn('apiPayloadDefault => ', defaultApiPayload)

    const calls = [
        call(fetchBrandFilter, defaultApiPayload),
        call(fetchClassificationTree, defaultApiPayload),
        call(fetchEtimClassFilter, defaultApiPayload),
        call(fetchPriceRangeFilter, defaultApiPayload),
        // call(getProductInfoMap),
        call(getAllProductProperties),
        // call(fetchAnagrafica),
    ]

    yield call(loadCustomFiltersOptions, defaultApiPayload)

    if (user.ruolo === 'admin') {
        calls.push(call(getUserGroups));
    }

    const [
        brandsRes,
        treeRes,
        etimClassRes,
        priceRangeRes,
        productPropertiesRes,
        userGroupsRes,
    ] = yield all(calls);

    yield put(setFilterOptions(constants.BRAND_FILTER, brandsRes.data))

    // yield put(setPriceRange(priceRangeRes.data));

    yield put(actions.setProductProperties(productPropertiesRes.data))

    yield put(setProductClassOptions(etimClassRes.data))
    yield put(setTree(treeRes.data))

    if (user.ruolo === 'admin') {
        yield put(actions.setUserGroups(userGroupsRes.data))
    } else {
        // Se non sono admin verifico che le colonne selezionate per la tabella personalizzata
        // siano tra quelle per cui il mio gruppo ha il permesso di visualizzazione
        const currentTableColumns = yield select(selectors.getTableColumns)

        if (currentTableColumns && currentTableColumns.length > 0) {
            const validProps = productPropertiesRes.data.map((p) => p.code)

            yield put(actions.setTableColumns(intersection(currentTableColumns, validProps)))
        }
    }

    yield put(fetchCollections())

    const productTreeRootsRes = yield call(fetchProductTreeRootsFilter)

    yield put(initProductTreeRoots(productTreeRootsRes.data));

    // Create the bootstrappedState object and send to reducer
    const filters = yield select((state) => state.filters)
    const productClasses = yield select((state) => state.productClasses)
    const sectors = yield select((state) => state.sectors)

    const bootstrappedState = {
        filters,
        productClasses,
        sectors,
    }

    yield put(actions.setBootstrappedAppState(bootstrappedState))
    // the bootstrappedState is setted, we can setIsChangingClassification to false so fetchItems can run
    yield put(setIsChangingClassification(false))
}

export default [
    takeEvery(actionTypes.BOOTSTRAP_APP, bootstrapApp),
    takeEvery(actionTypes.HIDE_LOADING_SPLASH_SCREEN, hideLoadingSplashScreen),
    takeEvery(actionTypes.LOAD_PREFERENCES, handleLoadPreferences),
    takeLatest(actionTypes.INITIALIZE_BOOTSTRAPPED_STATE, initializeBootstrappedState),
];
