import { put } from 'redux-saga/effects'
import {
    setProductClassAttributeLogic, setProductClassAttributeNumeric,
    toggleProductClassAttributeFilter
} from '../../../actions/productClassesActions';
import { generateRangeValues } from '../utils';

// Implement a strategy pattern in Javascript: an object used to switch the action we will dispatch
const etimFeatureStrategies = {
    alphanumeric: function* (id, feature) {
        for (const val of feature.value) {
            yield put(toggleProductClassAttributeFilter(id, val));
        }
    },
    numeric: function* (id, feature) {
        yield put(setProductClassAttributeNumeric(id, feature.value))
    },
    range: function* (id, feature) {
        // console.warn('range etim features => ', feature)
        const formattedValues = generateRangeValues(feature.value);

        // Dispatch each values as "min::max"
        for (const val of formattedValues) {
            yield put(toggleProductClassAttributeFilter(id, val));
        }
        //yield put(toggleProductClassAttributeFilter(id, feature.value))
    },
    logic: function* (id, feature) {
        const valueFromLabel = feature.value === true ? 'yes' : 'no';
        // console.warn("logic etim_features found")
        yield put(setProductClassAttributeLogic(id, valueFromLabel));
    }
}

export {
    etimFeatureStrategies,
}