import * as constants from '../../../constants'
import cx from 'classnames'
import { getFilteredAttrs, renderAttrValue } from './ItemDataCategoryTableUtils'
import isUndefined from 'lodash/isUndefined'
import isString from 'lodash/isString'
import React from 'react'

const RenderSimpleTextList = ({attributes, type}) => {

    const isRepeatable = type === constants.SECTION_TYPE_REPEATABLE
    const sections = isRepeatable ? attributes : [attributes]

    return sections.map((section, index) => {

        return (
            <table
                key={index}
                className={cx(
                    'table table-striped table-striped-alt table-hover table-article-info',
                    {
                        'table-articolo-info-multiple': isRepeatable,
                    }
                )}
            >
                <tbody>
                {getFilteredAttrs(section).map((attr, i) => {
                    const value = renderAttrValue(attr)

                    // debug log
                    // console.warn('value from RenderSimpleTextList => ', value)

                    if (value === null || isUndefined(value) || value === '') {
                        return null;
                    }

                    const regex = new RegExp(
                        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
                    )

                    return (
                        <tr key={i}>
                            <td>
                                {attr.label}
                                {isRepeatable && i === 0 ? ` ${index + 1}` : ''}
                            </td>
                            <td>
                                {isString(value) && value.match(regex) ? (
                                    <a
                                        href={value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {value}
                                    </a>
                                ) : (
                                    <strong dangerouslySetInnerHTML={{ __html: value }} />
                                )}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    })
}

export default RenderSimpleTextList