import { removeLocalStorageFilter } from '../../../actions/localstorageActions'
import { setFilterValuesHandler } from '../../../actions/filtersActions'
import * as constants from '../../../constants'

const filterMapping = {
    status: 'stato',
    fuoriCartaceo: 'fuori_listino_cartaceo',
    line: 'linea',
    model: 'modello',
    series: 'serie',
    listini: 'listini',
    sector: 'sector',
    brand: 'brand',
    priceRange: 'priceRange',
    fullText: 'fullText',
    productClass: 'productClass',
    default: 'generic',
};

export const handleRemoveFilter = (key, value, dispatch) => {
    //console.warn('handleRemoveFilter', key, value)
    let filter = filterMapping[key] || filterMapping.default
    if(
        key === constants.CUSTOM_FILTERS1 ||
        key === constants.CUSTOM_FILTERS2 ||
        key === constants.CUSTOM_FILTERS3 ||
        key === constants.CLASSE_CATEGORIA_SCONTO_VENDITA ||
        key === constants.CLASSE_PRODOTTO ||
        key === constants.TIPO_ARTICOLO ||
        key === constants.CATEGORIA_MERCEOLOGICA
    ){
        filter = key
        key = 'custom_filters'
    }
    dispatch(removeLocalStorageFilter(filter, key, value))
    dispatch(setFilterValuesHandler(filter, key, value))
}

