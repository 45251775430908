import { put, take } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { fetchProductClassAttributeData, toggleProductClassAttributeFilter } from '../../actions/productClassesActions';
import { etimFeatureStrategies } from './strategies/etimFeatureFiltersStrategies';
import { handleEtimFeatureInLocalStorage } from '../../utils/LocalStorageFilterUtils';

function* loadEtimAttributesFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadEtimAttributesFilters')
    //console.warn('chiamato loadEtimAttributesFilters')
    const storedEtimAttributes = storedFilters.etim_features || {};
    //console.log(storedEtimAttributes)
    if (Object.keys(storedEtimAttributes).length === 0) {
        emitSagaEvent('saga_end');
        console.log('saga_end from loadEtimAttributesFilters')
        return;
    }

    // Before we can proceed we need to wait all the etim attributes for the current selected filters are loaded
    console.log('before take FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS')
    yield take('FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS');
    console.log('AFTER take FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS')
    // const { success } = yield race({
    //     success: take('FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS'),
    //     timeout: delay(3000)  // Timeout of 3s (test and adapt)
    // });
    //
    // if (!success) {
    //     console.warn("The event FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS it was not dispatched or we don't got it, continue function execution.");
    // }

    // Iterate over the etim_featuers and apply the right strategy
    for (const [id, feature] of Object.entries(storedEtimAttributes)) {
        yield put(fetchProductClassAttributeData(id));
        console.log('in for inside loadEtimAttributesFilters')
        if(feature.type === 'range') yield take('FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS')

        // If there is a strategy for the type ( alphanumeric|logic|numeric ) it will be applied
        // otherwhise will dispatch the default toggleProductClassAttributeFilter
        const strategy = etimFeatureStrategies[feature.type];
        if (strategy) {
            yield* strategy(id, feature);
        } else {
            console.warn(`ETIM_FEATURES TYPE UNKNOWN: ${feature.type}. Dispatch default action.`);
            for (const val of feature.value) {
                yield put(toggleProductClassAttributeFilter(id, val));
            }
        }
    }

    emitSagaEvent('saga_end');
    console.log('saga_end from loadEtimAttributesFilters')
}

function* removeEtimAttributeFilters(payload) {
    // console.warn('removeEtimAttributeFilters invoked, payload => ', payload)

    if(!payload?.attribute && payload) handleEtimFeatureInLocalStorage(payload)

    payload?.attribute ? handleEtimFeatureInLocalStorage(payload?.attribute) : ''
}

export { loadEtimAttributesFilters, removeEtimAttributeFilters }