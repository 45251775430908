import { call, put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel'
import * as constants from '../../constants'
import * as filterSelectors from '../../selectors/filterSelectors'
import { setFilterValue } from '../../actions/filtersActions'

function* loadCustomFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadCustomFilters')
    const storedCustomFilters = storedFilters.custom_filters
    // console.warn('customFilters stored => ', storedCustomFilters)

    if(storedCustomFilters === undefined || storedCustomFilters === null || storedCustomFilters.length === 0) {
        emitSagaEvent('saga_end')
        return
    }

    const customFilter1Options = yield select(filterSelectors.getFilter, constants.CUSTOM_FILTERS1)
    const customFilter2Options = yield select(filterSelectors.getFilter, constants.CUSTOM_FILTERS2)
    // const classeCategoriaScontoVenditaOptions = yield select(filterSelectors.getFilter, constants.CLASSE_CATEGORIA_SCONTO_VENDITA)
    // const classeProdottoOptions = yield select(filterSelectors.getFilter, constants.CLASSE_PRODOTTO)
    // const tipoArticoloOptions = yield select(filterSelectors.getFilter, constants.TIPO_ARTICOLO)
    // const categoriaMerceologicaOptions = yield select(filterSelectors.getFilter, constants.CATEGORIA_MERCEOLOGICA)

    const filtersMap = [
        { type: constants.CUSTOM_FILTERS1, options: customFilter1Options.options },
        { type: constants.CUSTOM_FILTERS2, options: customFilter2Options.options },
        // { type: constants.CLASSE_CATEGORIA_SCONTO_VENDITA, options: classeCategoriaScontoVenditaOptions.options },
        // { type: constants.CLASSE_PRODOTTO, options: classeProdottoOptions.options },
        // { type: constants.TIPO_ARTICOLO, options: tipoArticoloOptions.options },
        // { type: constants.CATEGORIA_MERCEOLOGICA, options: categoriaMerceologicaOptions.options }
    ]

    if(yield call(activeCustomFilters,filtersMap)){
        emitSagaEvent('saga_end')
        return
    }

    for(const storedCustomFilter of storedCustomFilters) {
        // console.warn('customFilters current', customFilters[i])
        const {filterType, filterValue} = getCustomFiltersType(storedCustomFilter, filtersMap)

        if(!filterValue){
            continue
        }

        yield put(setFilterValue(filterType, filterValue))
        // console.warn('filterType and filtervalue => ', filterType, filterValue)
    }

    emitSagaEvent('saga_end')
    console.log('saga_end from loadCustomFilters')
}

export function* loadCustomFiltersBrandRelated(storedFilters){
    emitSagaEvent('saga_start')
    console.log('saga_start from loadCustomFilters')
    const storedCustomFilters = storedFilters.custom_filters
    // console.warn('customFilters stored => ', storedCustomFilters)

    if(storedCustomFilters === undefined || storedCustomFilters === null || storedCustomFilters.length === 0) {
        emitSagaEvent('saga_end')
        return
    }

    const classeCategoriaScontoVenditaOptions = yield select(filterSelectors.getFilter, constants.CLASSE_CATEGORIA_SCONTO_VENDITA)
    const classeProdottoOptions = yield select(filterSelectors.getFilter, constants.CLASSE_PRODOTTO)
    const tipoArticoloOptions = yield select(filterSelectors.getFilter, constants.TIPO_ARTICOLO)
    const categoriaMerceologicaOptions = yield select(filterSelectors.getFilter, constants.CATEGORIA_MERCEOLOGICA)

    const filtersMap = [
        { type: constants.CLASSE_CATEGORIA_SCONTO_VENDITA, options: classeCategoriaScontoVenditaOptions.options },
        { type: constants.CLASSE_PRODOTTO, options: classeProdottoOptions.options },
        { type: constants.TIPO_ARTICOLO, options: tipoArticoloOptions.options },
        { type: constants.CATEGORIA_MERCEOLOGICA, options: categoriaMerceologicaOptions.options }
    ]

    if(yield call(activeCustomFilters,filtersMap)){
        emitSagaEvent('saga_end')
        return
    }

    for(const storedCustomFilter of storedCustomFilters) {
        // console.warn('customFilters current', customFilters[i])
        const {filterType, filterValue} = getCustomFiltersType(storedCustomFilter, filtersMap)

        if(!filterValue){
            continue
        }

        yield put(setFilterValue(filterType, filterValue))
        // console.warn('filterType and filtervalue => ', filterType, filterValue)
    }

    emitSagaEvent('saga_end')
    console.log('saga_end from loadCustomFilters')
}

function* removeCustomFilters(storedFilters, filter, value){
    if(!storedFilters['custom_filters']){
        return false
    }

    if(!Array.isArray(storedFilters['custom_filters'])){
        return false
    }

    storedFilters['custom_filters'] = storedFilters['custom_filters'].filter(item => item !== value)

    // If we pass a undefined or null value, we need to check in state
    // if there are some values for this filter
    // and remove it from local storage
    if(!value){
        const customFilter = yield select(filterSelectors.getFilter, filter)

        const values = getActiveCustomFilterValue(customFilter)

        if(values.length === 0){
            console.warn(`${customFilter} not found or some problem to get the values`)
        }

        for ( const value of values){
            storedFilters['custom_filters'] = storedFilters['custom_filters'].filter(item => item !== value)
        }

    }

    if(storedFilters['custom_filters'].length === 0){
        delete storedFilters['custom_filters']
    }

    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        // Aggiorniamo il localStorage con i filtri modificati
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
    //console.warn('removeCustomFilters called, now storedFilters is  => ', storedFilters)
}

function* activeCustomFilters(filtersMap) {
    for (const { type } of filtersMap) {
        const customFilterState = yield select(filterSelectors.getFilter, type)
        //console.warn('customFilterState => ', customFilterState)

        if(customFilterState?.value.toJS().length > 0){
            return true
        }
    }
    return false
}

const getCustomFiltersType = (filterValue, filtersMap) => {
    for (const { type, options } of filtersMap) {
        const matchedFilter = options.find(customFilter => customFilter.value === filterValue);
        if (matchedFilter) {
            return { filterType: type, filterValue: matchedFilter }
        }
    }

    return false;
}

const getActiveCustomFilterValue = (customFilter) => {

    if(!customFilter.value) return []

    const values = customFilter.value.map(value => value.value)

    return values
}

export { loadCustomFilters, removeCustomFilters }