import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getStoredFilters, isLocalStorageAvailable } from '../utils/LocalStorageFilterUtils'
import { setIsLoadingFromLocalStorage } from '../actions/pageStateActions'
import { emitSagaEvent } from './sharedChannel'
import { fetchItemsFromLocalStorage } from '../actions/itemsActions'
import { actionTypes } from '../actions/filtersActions';
import {
    actionsType as localstorageType,
    setLocalStorageFilters,
    setLocalStorageFiltersSuccess
} from '../actions/localstorageActions';
import { loadBrandFilters } from './localStorageSagas/brandFilterSaga'
import { loadClassificationFilters } from './localStorageSagas/classificationFilterSaga';
import { loadEtimFiltersExportPanel } from './localStorageSagas/etimFilterExportPanelSaga';
import { loadEtimFilters } from './localStorageSagas/etimFilterSaga';
import { loadEtimAttributesFilters } from './localStorageSagas/etimAttributeFilterSaga';
import { loadFuoriCartaceoFilters } from './localStorageSagas/fuoriCartaceoFilterSaga';
import { loadPriceRangeFilters } from './localStorageSagas/priceRangeFilterSaga';
import { loadSectorFilters } from './localStorageSagas/sectorFilterSaga';
import { deleteStrategies } from './localStorageSagas/strategies/deleteStrategies';
import { loadStatusFilters } from './localStorageSagas/statusFilterSaga';
import { savingStrategies } from './localStorageSagas/strategies/savingStrategies';
import { loadCustomFilters } from './localStorageSagas/customFiltersSaga';

let isBatchMode = false

function* loadFiltersFromLocalStorage(){
    console.warn('Loading filters  from local storage')
    if (!isLocalStorageAvailable()) {
        yield put(setIsLoadingFromLocalStorage(false))
        return
    }

    emitSagaEvent('saga_start')
    console.log('saga_start from loadFiltersFromLocalStorage')
    yield put(setIsLoadingFromLocalStorage(true))

    const storedFilters = getStoredFilters()
    //console.log(storedFilters)
    // Start batch mode
    isBatchMode = true
    // Check for stored filters and dispatch the corresponding actions
    yield all([
        call(loadBrandFilters, storedFilters),
        call(loadStatusFilters, storedFilters),
        call(loadClassificationFilters, storedFilters),
        call(loadSectorFilters, storedFilters),
        call(loadEtimFilters, storedFilters),
        call(loadEtimAttributesFilters, storedFilters),
        call(loadFuoriCartaceoFilters, storedFilters),
        call(loadPriceRangeFilters, storedFilters),
        call(loadCustomFilters, storedFilters),
    ])

    // End batch mode
    isBatchMode = false
    // Once all filters are loaded, we dispatch fetchItems and finish
    yield put(fetchItemsFromLocalStorage(0))


    // Set isLoadingFromLocalStorage to false because all sagas are loaded
    // yield put(setIsLoadingFromLocalStorage(false))
    emitSagaEvent('saga_end')
    console.log('saga_end from loadFiltersFromLocalStorage')
}

// Saga function that remove the localStorage saved filters
function* resetLocalStorageFiltersSaga(){
    // Delete local storage filters
    localStorage.removeItem('userFilters')
    yield put(setLocalStorageFiltersSuccess( {}))
}

// Load filters for export panel
// TODO: one loadFiltersFromLocalStorage
function* loadFiltersForExportPanel() {
    console.log(' executing loadFiltersForExportPanel ')

    if(!isLocalStorageAvailable()){
        yield put(setIsLoadingFromLocalStorage(false))
        return false
    }

    emitSagaEvent('saga_start')
    console.log('saga_start from loadFiltersForExportPanel')

    yield put(setIsLoadingFromLocalStorage(true))
    const storedFilters = getStoredFilters()

    isBatchMode = true

    yield all([
        call(loadBrandFilters, storedFilters),
        call(loadStatusFilters, storedFilters),
        call(loadClassificationFilters, storedFilters),
        call(loadSectorFilters, storedFilters),
        call(loadEtimFiltersExportPanel, storedFilters),
        call(loadEtimAttributesFilters, storedFilters),
        // call(loadFuoriCartaceoFilters, storedFilters),
        call(loadPriceRangeFilters, storedFilters),
        call(loadCustomFilters, storedFilters),
    ])

    // End batch mode
    isBatchMode = false

    yield put(fetchItemsFromLocalStorage(0))
    emitSagaEvent('saga_end')
    console.log('saga_end from loadFiltersFromLocalStorage')
}

function* setLocalStorageFiltersSaga(action){

    // console.warn('action => ', action)
    localStorage.setItem('userFilters', JSON.stringify(action.payload.userFilters))

    yield put(setLocalStorageFiltersSuccess(action.payload.userFilters))
}

function* removeLocalStorageFilterSaga(action){
    // console.warn('removal action => ', action)

    const { filter, key, value } = action.payload

    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {}

    const strategy = deleteStrategies[key] || deleteStrategies.default

    yield call(strategy, storedFilters, filter, key, value)

    const newStoredFilters = {userFilters: getStoredFilters()}

    // console.warn('newStoredFilters => ', newStoredFilters)

    yield put(setLocalStorageFilters(newStoredFilters)) // may back to yield put(setLocalStorageFiltersSuccess(newStoredFilters))

}

function* addLocalStorageFilterSaga(action){
    // console.warn('action => ', action)

    const { filter, key, value } = action.payload

    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {}

    const strategy = savingStrategies[key] || savingStrategies.default

    yield call(strategy, storedFilters, filter, key, value)

    const newStoredFilters = {userFilters: getStoredFilters()}

    // console.warn('newStoredFilters => ', newStoredFilters)

    yield put(setLocalStorageFilters(newStoredFilters)) // may back to yield put(setLocalStorageFiltersSuccess(newStoredFilters))
}

export default [
    takeEvery(actionTypes.LOAD_FILTERS_FROM_LOCALSTORAGE, loadFiltersFromLocalStorage),
    takeEvery(actionTypes.RESET_LOCAL_STORAGE_FILTERS, resetLocalStorageFiltersSaga),
    takeEvery(localstorageType.LOAD_FILTERS_FOR_EXPORT_PANEL, loadFiltersForExportPanel),
    takeEvery(localstorageType.SET_LOCAL_STORAGE_FILTERS, setLocalStorageFiltersSaga),
    takeEvery(localstorageType.REMOVE_LOCAL_STORAGE_FILTER, removeLocalStorageFilterSaga),
    takeEvery(localstorageType.ADD_LOCAL_STORAGE_FILTER, addLocalStorageFilterSaga)
]