import React from 'react'
import { desideredOrder, getAttributeValue, sortChildrenProperties } from './IntegrationsUtils';

const PrezzoCalcolatoTable = ({prezzoCalcolato}) => {

    const itemsSorted = prezzoCalcolato?.children ? sortChildrenProperties(prezzoCalcolato, desideredOrder) : []

    const items = itemsSorted.filter(
        item => desideredOrder.includes(item.property)
    )

    return (
            <tr>
                {
                    items.map((item, index) =>
                        // item.value > 0 ? (
                        <td
                            key={index}
                            className="whitespace-nowrap text-right"
                            scope="row"
                        >
                            {getAttributeValue(item)}
                        </td>
                    // ) : null
                    )
                }
            </tr>
    )
}

export default PrezzoCalcolatoTable