const RenderPinaxo = ({data}) => {

    const catalogues = data
        .filter( (item) => item.type === 'C')
        .sort((a, b) => a.description.localeCompare(b.description))

    const listini = data
        .filter((item) => item.type === 'L')
        .sort((a, b) => a.description.localeCompare(b.description))

    // console.warn('cataloghi => ', catalogues)
    // console.warn('listini => ', listini)

    return (
        <div className="mx-auto px-2">
            <h2 className="text-base text-primary pt-2" key="cataloghi">Cataloghi</h2>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 mb-5">
                {
                    catalogues.length > 0 &&
                    catalogues.map((item) => {
                        return (
                            <a
                                key={item.document}
                                href={item.document}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative flex flex-col items-center justify-between h-full group text-primary hover:no-underline"
                            >
                                {/* pseudo element ::before  */}
                                <div
                                    className="absolute inset-0 z-0 before:absolute before:inset-0 before:bg-primary before:opacity-0 before:transition-opacity before:duration-300 before:content-[''] group-hover:before:opacity-100  group-hover:scale-105"
                                ></div>
                                <div className="relative z-10 flex-grow flex items-center justify-center">
                                    <img
                                        src={item.cover}
                                        alt={item.description}
                                        className="max-h-48 object-contain transition-transform duration-300 group-hover:scale-105"
                                    />
                                </div>
                                {/* Testo */}
                                <div className="relative z-10 mt-4 text-center group-hover:text-white">
                                    <p className="text-xs mb-0">{item.description}</p>
                                    <small>{item.release}</small>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
            <hr />
            <h2 className="text-base text-primary pt-5" key="listini">Listini</h2>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                {
                    listini.length > 0 &&
                    listini.map((item) => {
                        return (
                            <a
                                key={item.document}
                                href={item.document}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative flex flex-col items-center justify-between h-full group text-primary hover:no-underline"
                            >
                                {/* pseudo element ::before  */}
                                <div
                                    className="absolute inset-0 z-0 before:absolute before:inset-0 before:bg-primary before:opacity-0 before:transition-opacity before:duration-300 before:content-[''] group-hover:before:opacity-100  group-hover:scale-105"
                                ></div>
                                <div className="relative z-10 flex-grow flex items-center justify-center">
                                    <img
                                        src={item.cover}
                                        alt={item.description}
                                        className="max-h-48 object-contain transition-transform duration-300 group-hover:scale-105"
                                    />
                                </div>
                                {/* Testo */}
                                <div className="relative z-10 mt-4 text-center group-hover:text-white">
                                    <p className="text-xs mb-0">{item.description}</p>
                                    <small>{item.release}</small>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RenderPinaxo;