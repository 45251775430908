import { call, put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { selectors as sectorsSelectors } from '../../reducers/sectorsReducer';
import { setClassificationValue } from '../../actions/sectorsActions';
import {
    removeBrandRelatedFilterFromLocalStorage,
    updateLocalStorageStringValue
} from '../../utils/LocalStorageFilterUtils';
import { removeSectorFilter } from './sectorFilterSaga';
import { removeProductClassFilter } from './etimFilterSaga';

function* loadClassificationFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadClassificationFilters')
    const storedClassification = storedFilters.product_tree || false;
    if (storedClassification) {
        const classificationFilter = yield select(sectorsSelectors.getFilter);
        const classificationList = classificationFilter.toJS().options;

        const selectedClassification = classificationList.find(option => option.value === storedClassification);
        yield put(setClassificationValue(selectedClassification));
    }
    emitSagaEvent('saga_end')
    console.log('saga_end from loadClassificationFilters')
}

function* setClassificationFilters(filter, value){
    // Before adding new classification, be sure to remove all classification related value from local storage
    yield call(removeSectorFilter, filter, value)
    yield call(removeProductClassFilter)
    removeBrandRelatedFilterFromLocalStorage('all')
    updateLocalStorageStringValue(filter, value, 'add')
}

export { loadClassificationFilters, setClassificationFilters }