import { call, put } from 'redux-saga/effects'
import { removeGenericFilter } from '../genericFilterSaga'
import { removeProductClassFilter } from '../etimFilterSaga'
import { removeFullTextFilter } from '../fullTextFilterSaga'
import { removePriceRangeFilter } from '../priceRangeFilterSaga'
import { changeBrandFilterValue } from '../../../actions/filtersActions'
import { removeSectorFilter } from '../sectorFilterSaga'
import { removeFuoriCartaceoFilter } from '../fuoriCartaceoFilterSaga'
import { removeBrandFilter, removeBrandRelatedFilter } from '../brandFilterSaga';
import * as constants from '../../../constants'
import { removeCustomFilters } from '../customFiltersSaga';

// Implement a strategy pattern to execute the right method to handle the single filter removal
const brandRelatedFilters = ['status', 'line', 'model', 'series', 'listini']
const deleteStrategies = {
    ...Object.fromEntries(
        brandRelatedFilters.map((filter) => [
            filter,
            function* (storedFilters, filter, key, value) {
                yield call(removeBrandRelatedFilter, storedFilters, filter, key, value);
            },
        ])
    ),
    [constants.FUORI_CARTACEO_FILTER]: function* (storedFilters, filter, key, value) {
        yield call(removeFuoriCartaceoFilter, storedFilters, key, value)
    },
    brand: function* (_storedFilters, filter, _key, value){
        //yield put(changeBrandFilterValue({ value }))
        yield call(removeBrandFilter, value )
    },
    priceRange: function* (_storedFilters, filter, key, _value) {
        yield call(removePriceRangeFilter, key)
    },
    fullText: function* (_storedFilters, filter, key, _value){
        yield call(removeFullTextFilter, key)
    },
    custom_filters: function* (storedFilters, filter, _key, value) {
        yield call(removeCustomFilters, storedFilters, filter, value)
    },
    productClass: function* (_storedFilters, filter, _key, _value) {
        // Clear the etim_class from Storage.
        // Note: productClass filter is the etim_class payload.
        // this function is from etimFilterSaga
        yield call(removeProductClassFilter)
    },
    etim_class: function* (_storedFilters, filter, _key, _value) {
        // Clear the etim_class from Storage.
        // Note: productClass filter is the etim_class payload.
        // this function is from etimFilterSaga
        yield call(removeProductClassFilter)
    },
    sector: function* (_storedFilters, filter, key, value) {
        yield call(removeSectorFilter, key, value)
    },
    classification_path: function* (_storedFilters, filter, key, value) {
        yield call(removeSectorFilter, key, value)
    },
    [constants.REPLACEMENTS_FILTER]: function* (storedFilters, filter, key, _value) {
        yield call(removeGenericFilter, storedFilters, key, false);
    },
    [constants.OPTIONALS_FILTER]: function* (storedFilters, filter, key, _value) {
        yield call(removeGenericFilter, storedFilters, key, false);
    },
    // [constants.FUORI_CARTACEO_FILTER]: function* (storedFilters, filter, key, _value) {
    //     yield call(removeGenericFilter, storedFilters, key, null);
    // },
    [constants.ESPORTABILE_PER_CATALOGO_FILTER]: function* (storedFilters, filter, key, _value) {
        yield call(removeGenericFilter, storedFilters, key, null);
    },
    default: (storedFilters, key) => {
        console.warn('default called')
        delete storedFilters[key];
    },
}

export {
    deleteStrategies,
}