import { put } from 'redux-saga/effects'
import * as constants from '../../../constants';
import { changeBrandFilterValue, setFilterValue } from '../../../actions/filtersActions';
import { toggleTreeItem } from '../../../actions/sectorsActions';
import { setProductClassValue } from '../../../actions/productClassesActions';

const brandRelatedFilters = ['status', 'line', 'model', 'series', 'listini']

const setFiltersStrategies = {
    ...Object.fromEntries(
        brandRelatedFilters.map((filter) => [
            filter,
            function* ( filter, key, value) {
                // yield call(handleBrandRelatedFilterRemoval, storedFilters, filter, key, value);
                // console.warn('setFiltersStrategies for brand related filter called => ', filter, key, value)
                yield put(setFilterValue(key, { value }))
            },
        ])
    ),
    [constants.FUORI_CARTACEO_FILTER]: function* ( filter, key, value) {
        // Need to handle when fuori_listino_cartace has value
        yield put(setFilterValue(constants.FUORI_CARTACEO_FILTER, null))
    },
    sector: function* (filter, key, value) {
        yield put(toggleTreeItem(value))
        yield put(setFilterValue(key, { value }))
    },
    brand: function* (filter, _key, value){
        yield put(changeBrandFilterValue({ value }))

    },
    priceRange: function* (filter, key, _value) {
        // Need to handle when prezzo_listino_from and prezzo_listino_to has values
        yield put(setFilterValue(key, [0,0]))
    },
    fullText: function* (filter, key, _value){
        // Need to handle when fullText has value
        yield put(setFilterValue(key, ''))
    },
    custom_filters: function* (filter, key, value) {
        //console.warn('setFiltersStrategies', filter, key, value)
        yield put(setFilterValue(filter, { value }))
    },
    productClass: function* (filter, _key, _value) {
        // Need to handle when productClass has value
        yield put(setProductClassValue(null))
    },
    [constants.REPLACEMENTS_FILTER]: function* (filter, key, _value) {
        // Need to handle when filter has value
        yield put(setFilterValue(key, false))
    },
    [constants.OPTIONALS_FILTER]: function* (filter, key, _value) {
        // Need to handle when filter has value
        yield put(setFilterValue(key, false))
    },
    [constants.ESPORTABILE_PER_CATALOGO_FILTER]: function* (filter, key, _value) {
        // Need to handle when filter has value
        yield put(setFilterValue(key, null))
    },
    default: function* (filter, key, value) {
        yield put(setFilterValue(key, value))
    },
}

export {
    setFiltersStrategies,
}