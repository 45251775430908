import { put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { actions as sectorActions } from '../../actions/sectorsActions';
import { setProductClassValue } from '../../actions/productClassesActions';
import { selectors as productClassReducerSelectors } from '../../reducers/productClassesReducer'

function* loadEtimFiltersExportPanel(storedFilters) {

    emitSagaEvent('saga_start')
    console.log('saga_start from loadEtimFilters')
    const storedEtimClass = storedFilters.etim_class || null

    if(storedEtimClass) yield put(sectorActions.setSelectedItemTree(storedEtimClass))

    if(storedEtimClass) {
        const filterPropertiesImmutable = yield select(productClassReducerSelectors.getFilter)
        if(!filterPropertiesImmutable) return
        const filterProperties = filterPropertiesImmutable?.toJS()
        const matchedOption = filterProperties.options.find(option => option.value === storedEtimClass)

        // console.warn('filterProperties from loadEtimFilters', matchedOption);

        //console.warn('per SET_PRODUCT_CLASS_VALUE => ', testEtim)
        yield put(setProductClassValue(matchedOption))
    }

    emitSagaEvent('saga_end')
    console.log('saga_end from loadEtimFilters')
}

export { loadEtimFiltersExportPanel }