export const LOAD_FILTERS_FOR_EXPORT_PANEL = 'LOAD_FILTERS_FOR_EXPORT_PANEL'
export const LOAD_FILTERS_FOR_EXPORT_PANEL_SUCCESS = 'LOAD_FILTERS_FOR_EXPORT_PANEL_SUCCESS'
export const SET_LOCAL_STORAGE_FILTERS = 'SET_LOCAL_STORAGE_FILTERS'
export const SET_LOCAL_STORAGE_FILTERS_SUCCESS = 'SET_LOCAL_STORAGE_FILTERS_SUCCESS'
export const REMOVE_LOCAL_STORAGE_FILTER = 'REMOVE_LOCAL_STORAGE_FILTER'
export const ADD_LOCAL_STORAGE_FILTER = 'ADD_LOCAL_STORAGE_FILTER'


export const loadFiltersForExportPanel = () => ({
    type: LOAD_FILTERS_FOR_EXPORT_PANEL,
})

export const loadFiltersForExportPanelSuccess = () => ({
    type: LOAD_FILTERS_FOR_EXPORT_PANEL_SUCCESS,
})

export const setLocalStorageFilters = (userFilters) => {
    return {
        type: SET_LOCAL_STORAGE_FILTERS,
        payload: userFilters,
    }
}

export const setLocalStorageFiltersSuccess = (userFilters) => {
    return {
        type: SET_LOCAL_STORAGE_FILTERS_SUCCESS,
        payload: userFilters,
    }
}

export const removeLocalStorageFilter = (filter, key, value) => {
    return {
        type: REMOVE_LOCAL_STORAGE_FILTER,
        payload: { filter, key, value }
    }
}

export const addLocalStorageFilter = (filter, key, value) => {
    return {
        type: ADD_LOCAL_STORAGE_FILTER,
        payload: { filter, key, value }
    }
}

export const actionsType = {
    LOAD_FILTERS_FOR_EXPORT_PANEL,
    LOAD_FILTERS_FOR_EXPORT_PANEL_SUCCESS,
    SET_LOCAL_STORAGE_FILTERS,
    SET_LOCAL_STORAGE_FILTERS_SUCCESS,
    REMOVE_LOCAL_STORAGE_FILTER,
    ADD_LOCAL_STORAGE_FILTER,
}

export const actions = {
    loadFiltersForExportPanel,
    loadFiltersForExportPanelSuccess,
    setLocalStorageFilters,
    setLocalStorageFiltersSuccess,
    removeLocalStorageFilter,
    addLocalStorageFilter,
}