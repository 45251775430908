import { all, call, put, select, takeEvery, fork } from 'redux-saga/effects';
import { emitSagaEvent } from './sharedChannel'
import omit from 'lodash/omit';

import {
    actions,
    actionTypes,
    setFuoriCartaceoEnabled
} from '../actions/filtersActions';
import {
    actions as productClassesActions,
    fetchProductClassAttributesSuccess,
} from '../actions/productClassesActions';
import { fetchItems } from '../actions/itemsActions';
import { fetchTree } from './sectorsSaga';

import * as api from '../api';
import * as constants from '../constants';

import { delay } from 'redux-saga';
import { getIsChangingClassification, getIsLoadingFromLocalStorage } from '../reducers/pageStateReducer';
import { haveFuoriListinoCartaceoProperty } from '../helpers';
import { getApiPayload } from '../selectors/filterPayload';
import { changeBrandFilterSaga } from './filtersSaga/brandFilterSaga';
import { setFiltersStrategies } from './filtersSaga/strategies/setFiltersStrategies';
import { loadCustomFiltersBrandRelatedOptions, loadCustomFiltersOptions } from './filtersSaga/customFiltersSaga';

let isBatchMode = false

function* setFilterValueSaga(action) {
    if (yield* shouldSkipProcessing()) {
        return
    }
    // Check if the api payload have products with fuori_listino_cartaceo
    yield* handleFilterFuoriCartaceo()

    yield select((state) => state.filters.brands)
    // yield delay(500)
    yield put(fetchItems()) // Only fetch items if not in batch mode
}

function* shouldSkipProcessing(){
    const isChangingClassification = yield select(getIsChangingClassification)
    return isBatchMode || isChangingClassification
}
// Check if the api payload have products with fuori_listino_cartaceo
function* handleFilterFuoriCartaceo() {
    const apiPayload = yield select(getApiPayload)
    const haveFuoriCartaceo = yield call(haveFuoriListinoCartaceoProperty, apiPayload)

    yield put(setFuoriCartaceoEnabled(haveFuoriCartaceo))
}

// Reloads filter options from api
export function* refreshFiltersValues(apiPayload = null) {
    emitSagaEvent('saga_start')
    console.log('saga_start from refreshFiltersValues')

    const isLoadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)
    if (apiPayload === null) {
        apiPayload = yield select(getApiPayload);
    }

    yield fork(fetchTree);

    const brandApiPayload = omit(apiPayload, ['sigle_marca']);

    const [brandsRes, statusRes, priceRangeRes, etimClassRes, listiniRes] = yield all([
        call(api.fetchBrandFilter, brandApiPayload),
        call(api.fetchStatusFilter, apiPayload),
        call(api.fetchPriceRangeFilter, apiPayload),
        call(api.fetchEtimClassFilter, apiPayload),
        call(api.fetchListini, apiPayload),
        // call(api.fetchClassificationTree, apiPayload)
    ]);

    yield put(actions.setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
    yield put(actions.setFilterOptions(constants.STATUS_FILTER, statusRes.data));
    yield put(actions.setPriceRange(priceRangeRes.data));
    yield put(productClassesActions.setProductClassOptions(etimClassRes.data));
    yield put(actions.setFilterOptions(constants.LISTINI_FILTER, listiniRes.data));
    // yield put(setTree(treeRes.data))

    // If there is only one brand selected in filters, we get also all the brand related filters
    // line, model, series and listini
    const sigleMarca = apiPayload.sigle_marca
    const shouldFetch = sigleMarca !== undefined && (sigleMarca.length === 1 || sigleMarca.toJS().length === 1)

    if (shouldFetch) {
        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER),
            call(loadCustomFiltersBrandRelatedOptions, apiPayload)
        ])
    }

    // Load custom filters options
    yield call(loadCustomFiltersOptions, apiPayload)

    emitSagaEvent('saga_end')
    console.warn('saga_end from refreshFiltersValues')

}

export function* fetchFilterData(apiFn, apiPayload, filterName) {
    try {
        const res = yield call(apiFn, apiPayload);

        if(filterName === 'etim_features'){
            yield put(fetchProductClassAttributesSuccess(res))
        }else{
            yield put(actions.setFilterOptions(filterName, res.data));
        }

    } catch (err) {
        console.error(err);
    }
}

// EXPERIMENTAL
// a strategy that dispatch the right setValue based on filter key
export function* handleSetFiltersValueSaga(action){
    //console.warn('handleSetFiltersValueSaga called => ', action)

    const { filter, key, value } = action.payload

    const strategy = setFiltersStrategies[key] || setFiltersStrategies.default

    yield call(strategy, filter, key, value)
}

export default [
    takeEvery(actionTypes.SET_FILTER_VALUE, setFilterValueSaga),
    takeEvery(actionTypes.CHANGE_BRAND_FILTER_VALUE, changeBrandFilterSaga),
    takeEvery(actionTypes.REFRESH_FILTERS_VALUES, refreshFiltersValues),
    takeEvery(actionTypes.SET_FILTER_VALUES_HANDLER, handleSetFiltersValueSaga)
]
