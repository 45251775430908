import { all, fork } from 'redux-saga/effects';

import appSaga from './appSaga';
import collectionsSaga from './collectionsSaga';
import filtersSaga, { refreshFiltersValues } from './filtersSaga';
import localStorageSaga from './localStorageSaga'
import itemsSaga from './itemsSaga';
import productClassesSaga from './productClassesSaga';
import sectorsSaga from './sectorsSaga';
import userSaga from './userSaga';
import usersSaga from './usersSaga';
import { monitorSagaCompletion } from './monitorSaga';

export default function* rootSaga() {
    yield all([
        fork(monitorSagaCompletion),
        ...appSaga,
        ...collectionsSaga,
        ...filtersSaga,
        ...localStorageSaga,
        ...itemsSaga,
        ...productClassesSaga,
        ...sectorsSaga,
        ...userSaga,
        ...usersSaga
    ]);
}