import * as constants from '../../constants';
import isArray from 'lodash/isArray';

export const getSelectsValues = (state) => {
    let values = {};

    const keys = [
        constants.BRAND_FILTER,
        // constants.CATALOG_FILTER,
        constants.LINE_FILTER,
        constants.MODEL_FILTER,
        constants.SERIES_FILTER,
        constants.STATUS_FILTER,
        constants.LISTINI_FILTER,
        constants.CUSTOM_FILTERS1,
        constants.CUSTOM_FILTERS2,
        constants.CUSTOM_FILTERS3,
        constants.CLASSE_CATEGORIA_SCONTO_VENDITA,
        constants.CLASSE_PRODOTTO,
        constants.TIPO_ARTICOLO,
        constants.CATEGORIA_MERCEOLOGICA,

    ];

    keys.forEach((key) => {
        //console.log(state.filters[key].toJS());
        values[key] = state.filters[key].value
            ? isArray(state.filters[key].value.toJS())
                ? state.filters[key].value.toJS().map((entry) => entry.value)
                : state.filters[key].value.value
            : null;
    });

    return values;
}

export const nonFiltersKeys = [
    'per_page',
    'page',
    'locale',
    'product_tree',
    'include_accessori',
    'include_ricambi',
    'properties',
]

export const formatAttributeValue = (value, type) => {
    switch (type) {
        case constants.ATTRIBUTE_TYPE_NUMERIC:
        default:
            return value;
        case constants.ATTRIBUTE_TYPE_LOGIC:
            return value === 'yes';
        case constants.ATTRIBUTE_TYPE_ALPHANUMERIC:
            return value.toArray();
        case constants.ATTRIBUTE_TYPE_RANGE:
            return value.toArray().map((v) => {
                const [start, end] = v.split('::');
                return [parseFloat(start), parseFloat(end)];
            });
    }
}