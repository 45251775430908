import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import ArticleDetailsBody from './ArticleDetailsBody';
import ScrollToTopButton from './utils/ScrollToTopButton';
import scrollPosHandler from './utils/ScrollPosHandler';
import Loader from './utils/Loader';
import {
    /*fetchItemDetails,*/ fetchItemDetails,
    getDescrizioneMarcaFromArticolo,
} from '../helpers';
// import { getLocaleFromLanguageCode } from '../intl-helpers';
import { logAnalytic, fetchItemByBrandAndCode, fetchPinaxoData } from '../api';
import { selectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { normalizeArticleDetails } from '../helpers';

export class ArticleDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: props.details,
            etimClassDetails: props.etimClassDetails,
            isFetching: props.details === null,
        };

        //console.warn('props.details from ArticleDetailsModal => ', props.details)
        if (props.details === null) {
            this.fetchMissingData();
        }
    }

    /*componentDidMount() {
        setTimeout(() => {
            this.props.onMount();
        }, 500);
    }*/

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isFetching === true && this.state.isFetching === false) {
            this.props.onMount();
        }
    }

    componentWillUnmount() {
        if (this.doLogFn) {
            clearTimeout(this.doLogFn);
        }
    }

    async fetchMissingData() {
        let articleDetails = null;

        if (this.props.articleCode && this.props.articleBrand) {
            const { data } = await fetchItemByBrandAndCode(
                this.props.articleBrand,
                this.props.articleCode
            );

            articleDetails = normalizeArticleDetails(data);
        } else {
            articleDetails = await fetchItemDetails(this.props.article.id);
        }

        // Fetch the pinaxo data
        const payload = {
            sigla_marca: get(articleDetails, 'identificazione.sigla_marca.value.code'),
            product_code: get(articleDetails, 'identificazione.codice_articolo.value')
        }
        const pinaxoData = await fetchPinaxoData(payload)

        // console.warn('pinaxoData => ', pinaxoData)
        // console.warn('articleDetails (details) => ', articleDetails)

        if(pinaxoData) {
            articleDetails = {
                ...articleDetails,
                pinaxo: pinaxoData,
            }
        }

        //console.warn('articleDetails after pinaxo (details) => ', articleDetails)

        const user = this.props.user?.toJS() || { nome: '', cognome: ''};

        const logData = {
            utente: `${user.nome} ${user.cognome}`,
            brand: get(
                articleDetails,
                `identificazione.sigla_marca.value.label.${this.getLocale()}`
            ),
            acronym: get(articleDetails, 'identificazione.sigla_marca.value.code'),
            code: get(articleDetails, 'identificazione.codice_articolo.value'),
            code_description: get(
                articleDetails,
                `identificazione.descrizione_articolo.value.${this.getLocale()}`
            ),
            operation: 'Scheda prodotto',
        };

        // console.log(logData);
        this.doLogFn = setTimeout(() => {
            logAnalytic(logData);
        }, this.props.schedaProdottoLogTimeout * 1000);

        this.setState({
            details: articleDetails,
            etimClassDetails: articleDetails.etim,
            isFetching: false,
        });
    }

    getMarcaArticolo() {
        return getDescrizioneMarcaFromArticolo(this.state.details, this.getLocale());
    }

    getCodiceArticolo() {
        return get(this.state.details, 'identificazione.codice_articolo.value');
    }

    getDescrizioneArticolo() {
        return get(
            this.state.details,
            `identificazione.descrizione_articolo.value.${this.getLocale()}`
        );
    }

    isDeleted() {
        return !!get(this.state.details, 'metadata.deleted_at', false);
    }

    getLocale() {
        return this.props.user?.locale || 'it';
    }

    renderModal() {
        const {
            article,
            onClose,
            isPreview,
            isModal,
            language,
            // productProperties,
            initialCategories,
            scrollPos,
        } = this.props;

        const { details, etimClassDetails } = this.state;
        //console.warn('details from renderModal of ArticleDetailsModal => ', details)
        return (
            <Fragment>
                <div className="modal-header">
                    <div className="modal-title flex h5">
                        <div className="grow">
                            {this.getMarcaArticolo()} - {this.getCodiceArticolo()} -{' '}
                            <span style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{__html: this.getDescrizioneArticolo()}} />
                        </div>
                        {this.isDeleted() && (
                            <div className="grow" style={{ color: 'red' }}>
                                ARTICOLO ELIMINATO
                            </div>
                        )}
                        {isPreview === false && isModal && (
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        )}
                    </div>
                </div>
                <div className="modal-body" id={`article-detail-modal-${details.id}`}>

                    <div className="content">
                        <ArticleDetailsBody
                            article={article}
                            details={details}
                            isPreview={isPreview}
                            initialCategories={initialCategories}
                            language={language}
                            // productProperties={productProperties}
                            etimClassDetails={etimClassDetails}
                            scrollContainer={`article-detail-modal-${details.id}`}
                        />
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        padding: '0.4rem 0',
                        minHeight: '2.8rem',
                    }}
                >
                    {scrollPos > 300 && (
                        <div>
                            <ScrollToTopButton
                                scrollContainer={`article-detail-modal-${details.id}`}
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }

    renderLoader() {
        return (
            <div className="modal-body" id="article-detail-modal">
                <Loader />
            </div>
        );
    }

    render() {
        const { isPreview, isModal } = this.props;
        const { isFetching } = this.state;

        return (
            <div className="container">
                <div
                    className={isPreview || isModal === false ? '' : 'modal modal-wide active'}
                    id="article-detail-modal-id"
                >
                    <span className="modal-overlay" aria-label="Close" />
                    <div className={isPreview || isModal === false ? '' : 'modal-container'}>
                        {isFetching ? this.renderLoader() : this.renderModal()}
                    </div>
                </div>
            </div>
        );
    }
}

ArticleDetailsModal.propTypes = {
    details: PropTypes.object,
    etimClassDetails: PropTypes.object,
    initialCategories: PropTypes.array,
    isPreview: PropTypes.bool,
    onMount: PropTypes.func,
    language: PropTypes.string,
};

ArticleDetailsModal.defaultProps = {
    details: null,
    etimClassDetails: null,
    initialCategories: [],
    isPreview: false,
    isModal: true,
    onMount: () => {},
};

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
        schedaProdottoLogTimeout: appSelectors.getSchedaProdottoLogTimeout(state),
    };
}

const ConnectedArticleDetailsModal = connect(mapStateToProps)(ArticleDetailsModal);

export default scrollPosHandler(ConnectedArticleDetailsModal);
