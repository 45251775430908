import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderSearch from '../components/HeaderSearch';
import * as filterSelectors from '../selectors/filterSelectors'
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';

export class HeaderSearchContainer extends Component {
    render() {
        return <HeaderSearch {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        fullTextFilter: filterSelectors.getFilter(state, constants.FULL_TEXT_FILTER),
        fullTextSearchMode: appSelectors.getFullTextSearchMode(state),
    };
}

export default connect(mapStateToProps)(HeaderSearchContainer);
