import React, { Component } from 'react';
import { Trans, /*i18nMark,*/ withI18n } from '@lingui/react';
import Highlighter from 'react-highlight-words';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import matchSorter from 'match-sorter';

import MatchWhenRole from './routing/MatchWhenRole';
import Loader from './utils/Loader';
import SortableTableHeader from './utils/SortableTableHeader';
import ProcessDetailsModal from './ProcessDetailsModal';
import { getProcesses, fetchBrandFilter } from '../api';
import {
    BRAND_PROCESS_IMPORT_PRODUCTS,
    BRAND_PROCESS_REMOVE_PRODUCTS,
    BRAND_PROCESS_INDEX_PRODUCTS,
    BRAND_PROCESS_REMOVE_INDEXED_PRODUCTS,
    BRAND_PROCESS_REPROJECT_PRODUCTS,
    BRAND_PROCESS_IMPORT_PATCH,
    BRAND_PROCESS_EXPORT_PRODUCTS,
} from '../constants';
import { connect } from 'react-redux'
import { getFromInternalPage, getLoadedFromOtherPages } from '../reducers/pageStateReducer'
import { setIsLoadingFromLocalStorage, setLoadedFromOthersPages } from '../actions/pageStateActions';

function buildBrandsData(processes, brandsData) {
    let brands = {};

    processes.forEach((process) => {
        if (isUndefined(process.info.sigla_marca) && isUndefined(process.info.sigle_marca)) {
            return;
        }

        const siglaMarca = isUndefined(process.info.sigle_marca) ? process.info.sigla_marca : process.info.sigle_marca[0];

        if (
            brands[siglaMarca] ||
            process.code === BRAND_PROCESS_INDEX_PRODUCTS ||
            process.code === BRAND_PROCESS_EXPORT_PRODUCTS
        ) {
            return;
        }

        const brandDetail = find(brandsData, (brand) => brand.code === siglaMarca);

        brands[siglaMarca] = {
            code: siglaMarca,
            description: brandDetail ? brandDetail.description.it_IT : siglaMarca,
            operation: process.code,
            status: process.status,
            articles_number: process.info.created,
            errorReason: process.info.reason,
            date: process.ended_at,
            info: process.info,
        };
    });

    brandsData.forEach((brand) => {
        if (brands[brand.code]) {
            return;
        }

        brands[brand.code] = {
            code: brand.code,
            description: brand.description.it_IT,
            operation: null,
            status: null,
            articles_number: null,
            date: null,
        };
    });

    return sortBy(Object.values(brands), 'description');
}

class ProcessesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brands: [],
            isFetching: true,
            showModal: false,
            currentBrandCode: null,
            brandCodeFilter: '',
            brandDescFilter: '',
            statusFilter: '',
            sorting: {
                sortBy: null,
                sortDirection: 'asc',
            },
        };
    }

    async componentDidMount() {
        this.props.dispatch(setIsLoadingFromLocalStorage(false))

        const brands = await this.fetchData();

        this.setState({
            isFetching: false,
            brands,
        });
    }

    componentWillUnmount() {
        this.props.dispatch(setLoadedFromOthersPages(true))
    }

    async fetchData() {
        const [processesXhr, brandsXhr] = await Promise.all([getProcesses(), fetchBrandFilter()]);

        return buildBrandsData(processesXhr.data, brandsXhr.data);
    }

    handleShowDetails(code) {
        this.setState({
            showModal: true,
            currentBrandCode: code,
        });
    }

    closeDetails = () => {
        this.setState({
            showModal: false,
            currentUserId: null,
        });
    };

    getCurrentProcess() {
        return this.state.brands.find((b) => b.code === this.state.currentBrandCode);
    }

    getBrandsToDisplay() {
        const { brands, statusFilter, brandCodeFilter, brandDescFilter, sorting } = this.state;

        let brandsToDisplay = brands;

        if (statusFilter !== '') {
            if (statusFilter === BRAND_PROCESS_IMPORT_PRODUCTS) {
                brandsToDisplay = filter(brands, (b) => {
                    return includes(
                        [BRAND_PROCESS_IMPORT_PRODUCTS, BRAND_PROCESS_IMPORT_PATCH],
                        b.operation
                    );
                });
            } else {
                brandsToDisplay = filter(brands, { operation: statusFilter });
            }
        }

        if (brandCodeFilter !== '') {
            brandsToDisplay = matchSorter(brandsToDisplay, brandCodeFilter, {
                keys: ['code'],
                threshold: matchSorter.rankings.CONTAINS,
            });
        }

        if (brandDescFilter !== '') {
            brandsToDisplay = matchSorter(brandsToDisplay, brandDescFilter, {
                keys: ['description'],
                threshold: matchSorter.rankings.CONTAINS,
            });
        }

        if (sorting.sortBy) {
            brandsToDisplay = sortBy(brandsToDisplay, sorting.sortBy);

            if (sorting.sortDirection === 'desc') {
                brandsToDisplay.reverse();
            }
        }

        return brandsToDisplay;
    }

    refreshList = async () => {
        const brands = await this.fetchData();

        this.setState({
            brands,
        });
    };

    sortBy = (attr) => {
        const prevSorting = this.state.sorting;

        this.setState({
            sorting: {
                sortBy: attr,
                sortDirection:
                    attr === prevSorting.sortBy
                        ? prevSorting.sortDirection === 'asc'
                            ? 'desc'
                            : 'asc'
                        : 'asc',
            },
        });
    };

    render() {
        const { i18n } = this.props;
        const { showModal, isFetching, brandCodeFilter, brandDescFilter, statusFilter, sorting } =
            this.state;

        return (
            <>
                {showModal && (
                    <ProcessDetailsModal
                        onClose={this.closeDetails}
                        onDeleteSuccess={this.refreshList}
                        process={this.getCurrentProcess()}
                    />
                )}
                <div className="mx-1">
                    <div className="grid grid-cols-12 gap-1">
                        <div className="col-span-full text-center mt-2">
                            <h2 className="text-primary">
                                <Trans id="Brand management" />
                            </h2>
                        </div>
                        <div className="col-span-4 my-2">
                            <input
                                type="text"
                                className="form-input"
                                placeholder={i18n._('filter:brand:acronym')}
                                defaultValue={brandCodeFilter}
                                onChange={(e) => this.setState({ brandCodeFilter: e.target.value })}
                            />
                        </div>
                        <div className="col-span-4 my-2">
                            <input
                                type="text"
                                className="form-input"
                                placeholder={i18n._('filter:brand:description')}
                                defaultValue={brandDescFilter}
                                onChange={(e) => this.setState({ brandDescFilter: e.target.value })}
                            />
                        </div>
                        <div className="col-span-4 my-2">
                            <select
                                className="form-select"
                                value={statusFilter}
                                onChange={(e) => this.setState({ statusFilter: e.target.value })}
                            >
                                <option value="">{i18n._('filter:status')}</option>
                                <option value={BRAND_PROCESS_IMPORT_PRODUCTS}>
                                    {i18n._('import')}
                                </option>
                                <option value={BRAND_PROCESS_REMOVE_PRODUCTS}>
                                    {i18n._('remove_products')}
                                </option>
                                <option value={BRAND_PROCESS_REMOVE_INDEXED_PRODUCTS}>
                                    {i18n._('remove_indexed_products')}
                                </option>
                                <option value={BRAND_PROCESS_REPROJECT_PRODUCTS}>
                                    {i18n._('reproject_products')}
                                </option>
                            </select>
                        </div>
                        <div className="col-span-full">
                            {isFetching ? (
                                <Loader />
                            ) : (
                                <table className="table table-striped table-hover table-dense">
                                    <thead>
                                        <tr>
                                            <SortableTableHeader
                                                attr="code"
                                                onClick={this.sortBy}
                                                sorting={sorting}
                                                headerStyle={{ width: '25%' }}
                                            >
                                                <Trans id="brand:acronym" />
                                            </SortableTableHeader>
                                            <SortableTableHeader
                                                attr="description"
                                                onClick={this.sortBy}
                                                sorting={sorting}
                                                headerStyle={{ width: '25%' }}
                                            >
                                                <Trans id="brand:description" />
                                            </SortableTableHeader>
                                            <SortableTableHeader
                                                attr="operation"
                                                onClick={this.sortBy}
                                                sorting={sorting}
                                                headerStyle={{ width: '40%' }}
                                            >
                                                <Trans id="status" />
                                            </SortableTableHeader>
                                            <th style={{ width: '10%' }}>
                                                <Trans id="detail" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getBrandsToDisplay().map((brand, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <Highlighter
                                                            searchWords={[brandCodeFilter]}
                                                            textToHighlight={brand.code || ''}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Highlighter
                                                            searchWords={[brandDescFilter]}
                                                            textToHighlight={
                                                                brand.description || ''
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {brand.operation ? (
                                                            <span>
                                                                <Trans id={brand.operation} /> (
                                                                <Trans id={brand.status} />)
                                                            </span>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {brand.operation ? (
                                                            <span
                                                                onClick={() =>
                                                                    this.handleShowDetails(
                                                                        brand.code
                                                                    )
                                                                }
                                                                className="c-hand text-primary"
                                                            >
                                                                <i className="icon icon-search" />
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const ProcessesListIntl = withI18n()(ProcessesList);

function mapStateToProps(state) {
    return {
        fromInternalPage: getFromInternalPage(state), // Mappa il selettore per `fromInternalPage`
    }
}

export default MatchWhenRole(connect(mapStateToProps)(withI18n()(ProcessesList)), 'admin')
