import { call, put, select } from 'redux-saga/effects'
import { setFilterValue } from '../../actions/filtersActions';
import { emitSagaEvent } from '../sharedChannel';
import { fetchFilterData } from '../filtersSaga';
import * as api from '../../api';
import * as constants from '../../constants';
import * as filterSelectors from '../../selectors/filterSelectors';

// TODO: move here the logic to load status filter because is NOT BRAND RELATED

function* loadStatusFilters(storedFilters){
    emitSagaEvent('saga_start')
    yield call(fetchFilterData, api.fetchStatusFilter, storedFilters, constants.STATUS_FILTER)

    yield call(loadValue, storedFilters)
    emitSagaEvent('saga_end')
}

function* loadValue(storedFilters){
    const storedStatuses = storedFilters.stato

    if(!storedStatuses){
        return
    }

    const statusFilterOptions = yield select(filterSelectors.getFilter, constants.STATUS_FILTER)

    // Avoiding toggle effect, this logic need to be improved checking if the value are the same as the local stored ones
    // if statusFilterOptions has value, mean the filter is already set in the state, if we call setFilterValue we have toggle effect
    // so the selected values are being deselect
    if(statusFilterOptions.value.toJS().length > 0){
        return
    }

    for  (const status of storedStatuses){
        const matchedOption = statusFilterOptions.options.find(option => option.value === status)

        yield put(setFilterValue(constants.STATUS_FILTER, matchedOption))
    }

}
// now i'm handling as brand related, need to use this instead
function* removeStatusFilter(storedFilters, key, value) {
    if (storedFilters['stato']) {
        // Rimuoviamo il valore specifico dal filtro (per esempio, se ci sono più valori)
        if (Array.isArray(storedFilters['stato'])) {
            storedFilters['stato'] = storedFilters['stato'].filter(item => item !== value);

            // Se l'array è vuoto, rimuoviamo anche la chiave dal localStorage
            if (storedFilters['stato'].length === 0) {
                delete storedFilters['stato'];
            }
        } else {
            // Se è un valore singolo, rimuoviamo direttamente la chiave
            delete storedFilters['stato'];
        }

        // Se non ci sono più filtri, rimuoviamo l'intera chiave dal localStorage
        if (Object.keys(storedFilters).length === 0) {
            localStorage.removeItem('userFilters');
        } else {
            // Aggiorniamo il localStorage con i filtri modificati
            localStorage.setItem('userFilters', JSON.stringify(storedFilters));
        }
    }

    yield put(setFilterValue(key, { value }))
}

export { loadStatusFilters, removeStatusFilter }