import { call, put } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import { selectors as sectorsSelectors } from '../../reducers/sectorsReducer';
import { checkAndToggle } from './utils';
import { getFilterFromLocalStorage, updateLocalStorage } from '../../utils/LocalStorageFilterUtils';

function* loadSectorFilters(storedFilters) {
    emitSagaEvent('saga_start')
    console.log('saga_start from loadSectorFilters')
    const storedSector = storedFilters.settore || null
    const storedMacrofamiglia = storedFilters.macrofamiglia || null
    const storedFamiglia = storedFilters.famiglia || null
    const storedClassificationPath = storedFilters.classification_path || null

    yield checkAndToggle(storedSector, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedMacrofamiglia, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedFamiglia, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedClassificationPath, sectorsSelectors.getSelectedItem)

    emitSagaEvent('saga_end')
    console.log('saga_end from loadSectorFilters')
}

function* removeSectorFilter(key, value) {
    // In the sector filter we handle different api paramenters:
    // - classification_path
    // - settore
    // - famiglia
    // - macrofamiglia
    // when removing sector filter, we need to be sure to remove all of them if are stored in localStorage
    const keysToRemove = ['classification_path', 'settore', 'famiglia', 'macrofamiglia'];
    // console.warn('we are in removeSectorFilter', key, value)
    for (const sectorKey of keysToRemove) {
        const storedValue = getFilterFromLocalStorage(sectorKey);
        // console.warn('storedValue from sector filter removal => ', storedValue)
        if (storedValue) {
            yield call(updateLocalStorage, sectorKey, '', 'remove');
        }
    }
}
export { loadSectorFilters, removeSectorFilter }