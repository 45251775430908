import types from '../actions/pageStateActions';
import { Record } from 'immutable';

// Define initial state with Immutable.js Record
const PageStateRecord = Record({
    fromInternalPage: false,
    isLoadingFromLocalStorage: true,
    loadedFromOthersPages: false,
    isChangingClassification: false,
})

export const DEFAULT_STATE = PageStateRecord();

export default function pageStateReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.SET_FROM_INTERNAL_PAGE:
            return state.set('fromInternalPage', action.payload)
        case types.IS_LOADING_FROM_LOCAL_STORAGE:
            return state.set('isLoadingFromLocalStorage', action.payload)
        case types.SET_LOADED_FROM_OTHERS_PAGES:
            return state.set('loadedFromOthersPages', action.payload)
        case types.IS_CHANGING_CLASSIFICATION:
            return state.set('isChangingClassification', action.payload)
        default:
            return state
    }
}

// Selector
export const getFromInternalPage = (state) => state.pageState.fromInternalPage
export const getIsLoadingFromLocalStorage = (state) => state.pageState.isLoadingFromLocalStorage
export const getLoadedFromOtherPages = (state) => state.pageState.loadedFromOthersPages
export const getIsChangingClassification = (state) => state.pageState.isChangingClassification