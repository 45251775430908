import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import * as filterSelectors from '../selectors/filterSelectors'
import { selectors as productClassReducerSelectors } from '../reducers/productClassesReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';
import { actions, changeBrandFilterValue, setFilterValue } from '../actions/filtersActions';
import {
    setClassificationValue,
    toggleTreeItem
} from '../actions/sectorsActions';
import {
    getFilterFromLocalStorage,
    filterMap,
} from '../utils/LocalStorageFilterUtils';
import { actions as productClassesActions } from '../actions/productClassesActions';
import { getFromInternalPage } from '../reducers/pageStateReducer'
import { setIsLoadingFromLocalStorage } from '../actions/pageStateActions'
import { addLocalStorageFilter, removeLocalStorageFilter } from '../actions/localstorageActions';


function getValidFilterOptions(node) {
    if (node === null) {
        return null;
    }

    if (node.children.size === 0) {
        return null;
    }

    return node.children.map((l) => l.id);
}

export class SidebarContainer extends Component {

    handleFilterChange = (filterKey, selectedFilter) => {

        console.warn('filterKey and selectedFilter', filterKey, selectedFilter)
        this.updateLocalStorage(filterKey, selectedFilter)
        this.updateFilterState(filterKey, selectedFilter)
    }

    // Helper function to update local storage
    updateLocalStorage = (filterKey, selectedFilter) => {
        // TODO: dispatch localStorage action to add/remove data from localStorage
        // in future is better to put this logic outside ?

        // Be sure to tell app we are not loading from local storage, we can remove it
        this.props.dispatch(setIsLoadingFromLocalStorage(false))

        // Get the filter name based on filterKey
        const filterName = filterMap[filterKey];
        // console.warn('handleFilterChange', filterName, filterKey, selectedFilter);

        if(filterKey === 'brand') {
            this.props.dispatch(removeLocalStorageFilter(filterName, filterKey, selectedFilter?.value ? selectedFilter.value : null))
        }

        // Check if is classification (product_tree in API payload and so in local storage)
        if (filterKey === 'classification') {
            // console.warn('handleFilterChange is classification/product_tree - debug', filterName, filterKey, selectedFilter?.value);
            this.props.dispatch(addLocalStorageFilter(filterName, filterKey, selectedFilter?.value));
        }

        if(filterName === 'custom_filters') {
            // console.warn('selected custom filter => ', selectedFilter?.value)
            this.props.dispatch(removeLocalStorageFilter(filterKey, filterName, selectedFilter?.value))
            return
        }

        // Check if is classification path
        const classificationKeys = ['classification_path', 'etim_class', 'fuoriCartaceo'];
        const isClassificationPath = classificationKeys.includes(filterKey);
        if (isClassificationPath) {
            // console.warn('we are dispatching with value! and isClassification is =>', isClassificationPath);
            this.props.dispatch(removeLocalStorageFilter(filterName, filterKey, null));
        }

        if (!isClassificationPath && selectedFilter === null) {
            this.props.dispatch(removeLocalStorageFilter(filterName, filterKey, null));
        } else if (selectedFilter) {
            const currentStoredFilter = getFilterFromLocalStorage(filterKey);

            if (currentStoredFilter?.includes(selectedFilter.value)) {
                this.props.dispatch(removeLocalStorageFilter(filterName, filterKey, selectedFilter.value));
            }
        }
    }

    // Helper function to update State and dispatch action
    updateFilterState = (filterKey, selectedFilter) => {

        if(filterKey === 'brand') {
            // console.warn('updateFilterState', filterKey, selectedFilter)
            this.props.dispatch(changeBrandFilterValue(selectedFilter))
            return
        }

        if(filterKey === 'fuoriCartaceo'){
            this.props.dispatch(setFilterValue(constants.FUORI_CARTACEO_FILTER, selectedFilter))
            return
        }
        if(filterKey === 'classification'){
            //console.warn('updateFilterState from sidebar container', filterKey, selectedFilter)
            this.props.dispatch(setClassificationValue(selectedFilter))
            return
        }

        if (filterKey === 'classification_path') {
            this.props.dispatch(toggleTreeItem(selectedFilter.id, selectedFilter.depth))
            return
        }

        if(filterKey === 'etim_class') {
            if(!selectedFilter){
                this.props.dispatch(productClassesActions.setProductClassValue(selectedFilter))
                return
            }
            const filterProperties = this.props['productClassFilter']?.toJS()

            const matchedOption = filterProperties.options.find(option => option.value === selectedFilter.id)
            selectedFilter?.value ? this.props.dispatch(productClassesActions.setProductClassValue(selectedFilter)) : this.props.dispatch(productClassesActions.setProductClassValue(matchedOption))
            // this.props.dispatch(productClassesActions.setProductClassValue(matchedOption))
            return
        }

        const filterConstant = constants[`${filterKey.toUpperCase()}_FILTER`]
        if(filterConstant !== undefined) {
            this.props.dispatch(setFilterValue(filterConstant, selectedFilter))
            return
        }

        this.props.dispatch(setFilterValue(filterKey, selectedFilter))

    }


    render() {
        return <Sidebar {...this.props} onFilterChange={this.handleFilterChange}/>
    }
}

function mapStateToProps(state) {
    const productProperties = appSelectors.getProductProperties(state);

    const classificationType = sectorsSelectors.getFilterValue(state);
    let selectedNode = null;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        selectedNode = sectorsSelectors.getSelectedTreeItemRecord(state);
    }

    // console.log('selectors.getFilter(state, constants.LINE_FILTER),', selectors.getFilter(state, constants.LINE_FILTER).toJS())

    return {
        classificationFilter: sectorsSelectors.getFilter(state),
        brandFilter: filterSelectors.getFilter(state, constants.BRAND_FILTER),
        // listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        // optionalsFilter: selectors.getFilter(state, constants.OPTIONALS_FILTER),
        priceRangeFilter: filterSelectors.getFilter(state, constants.PRICE_RANGE_FILTER),
        isBrandFixed: appSelectors.getIsBrandFixed(state),
        language: appSelectors.getLanguage(state),
        showPriceRangeFilter: !!productProperties.prezzo_listino,
        statusFilter: filterSelectors.getFilter(state, constants.STATUS_FILTER),
        lineFilter: filterSelectors.getFilter(state, constants.LINE_FILTER),
        modelFilter: filterSelectors.getFilter(state, constants.MODEL_FILTER),
        seriesFilter: filterSelectors.getFilter(state, constants.SERIES_FILTER),
        listiniFilter: filterSelectors.getFilter(state, constants.LISTINI_FILTER),
        fuoriCartaceoFilter: filterSelectors.getFilter(state, constants.FUORI_CARTACEO_FILTER),
        esportabilePerCatalogoFilter: filterSelectors.getFilter(
            state,
            constants.ESPORTABILE_PER_CATALOGO_FILTER
        ),
        productClassFilter: productClassReducerSelectors.getFilter(state),
        //selectedItem: sectorsSelectors.getSelectedItem(state),
        enableEsportabilePerCatalogoFilter: appSelectors.getEnableEsportabilePerCatalogoFilter(state),
        customFilter1: filterSelectors.getFilter(state, constants.CUSTOM_FILTERS1),
        customFilter2: filterSelectors.getFilter(state, constants.CUSTOM_FILTERS2),
        customFilter3: filterSelectors.getFilter(state, constants.CUSTOM_FILTERS3),
        classeProdottiScontoVendita: filterSelectors.getFilter(state, constants.CLASSE_CATEGORIA_SCONTO_VENDITA),
        classeProdotto: filterSelectors.getFilter(state, constants.CLASSE_PRODOTTO),
        tipoArticolo: filterSelectors.getFilter(state, constants.TIPO_ARTICOLO),
        categoriaMerceologica: filterSelectors.getFilter(state, constants.CATEGORIA_MERCEOLOGICA),
        validFilterOptions: getValidFilterOptions(selectedNode),
        fromInternalPage: getFromInternalPage(state),
        haveFuoriListinoCartaceo: filterSelectors.getHaveFuoriListinoCartaceo(state)
    };
}

export default connect(mapStateToProps)(SidebarContainer);
