import { actionTypes } from '../actions/filtersActions';
import { Record, List } from 'immutable';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { BooleanStateRecord, OptionRecord, SelectFilterRecord } from './helpers';
import * as constants from '../constants';
import { SET_FIXED_BRAND } from '../actions/appActions';

// const SimpleFilterRecord = Record({
//     enabled: false,
//     value: false,
// });

const FullTextFilterRecord = Record({
    enabled: true,
    value: '',
    mode: 'legacy',
});

const PriceRangeFilterRecord = Record({
    enabled: true,
    min: 0,
    max: 99999,
    value: [0, 0],
});

const StateRecord = Record({
    [constants.BRAND_FILTER]: SelectFilterRecord({ enabled: true, value: List([]) }),
    //[constants.CATALOG_FILTER]: SelectFilterRecord(),
    [constants.FULL_TEXT_FILTER]: FullTextFilterRecord({
        enabled: true,
        value: '',
    }),
    [constants.LINE_FILTER]: SelectFilterRecord({ value: List([]) }),
    // [constants.OPTIONALS_FILTER]: SimpleFilterRecord(),
    [constants.MODEL_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.SERIES_FILTER]: SelectFilterRecord({ value: List([]) }),
    // [constants.REPLACEMENTS_FILTER]: SimpleFilterRecord(),
    // [constants.GOING_OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    // [constants.OUT_OF_PRODUCTION_FILTER]: SimpleFilterRecord(),
    [constants.STATUS_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.PRICE_RANGE_FILTER]: PriceRangeFilterRecord(),
    [constants.LISTINI_FILTER]: SelectFilterRecord({ value: List([]) }),
    [constants.FUORI_CARTACEO_FILTER]: SelectFilterRecord({
        enabled: true,
        options: List([
            new OptionRecord({
                value: 'true',
                label: 'yes',
                code: 'true',
                extra: null,
            }),
            new OptionRecord({
                value: 'false',
                label: 'no',
                code: 'false',
                extra: null,
            }),
        ]),
    }),
    [constants.ESPORTABILE_PER_CATALOGO_FILTER]: SelectFilterRecord({
        enabled: true,
        options: List([
            new OptionRecord({
                value: 'true',
                label: 'yes',
                code: 'true',
                extra: null,
            }),
            new OptionRecord({
                value: 'false',
                label: 'no',
                code: 'false',
                extra: null,
            }),
        ]),
    }),
    haveFuoriListinoCartaceo: BooleanStateRecord(),
    [constants.CUSTOM_FILTERS1]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.CUSTOM_FILTERS2]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.CUSTOM_FILTERS3]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.CLASSE_CATEGORIA_SCONTO_VENDITA]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.CLASSE_PRODOTTO]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.TIPO_ARTICOLO]: SelectFilterRecord({ enabled: true, value: List([])}),
    [constants.CATEGORIA_MERCEOLOGICA]: SelectFilterRecord({ enabled: true, value: List([])}),
});

export const DEFAULT_STATE = StateRecord();

function createOptionObject(key, o) {
    switch (key) {
        case constants.BRAND_FILTER:
        case constants.STATUS_FILTER:
        case constants.LINE_FILTER:
        case constants.MODEL_FILTER:
        case constants.SERIES_FILTER:
            return {
                label: o.description,
                value: o.id,
                code: o.code,
            };
        case constants.CUSTOM_FILTERS1:
        case constants.CUSTOM_FILTERS2:
        case constants.CUSTOM_FILTERS3:
        case constants.CLASSE_CATEGORIA_SCONTO_VENDITA:
        case constants.CLASSE_PRODOTTO:
        case constants.TIPO_ARTICOLO:
        case constants.CATEGORIA_MERCEOLOGICA:
            return {
                label: o.label,
                value: o.id,
                code: o.code,
            }
        case constants.LISTINI_FILTER:
            return {
                label: o.descrizione_listino,
                value: o.id,
                code: o.codice_listino,
                extra: {
                    codice_listino: o.codice_listino,
                    codice_listino_prod: o.codice_listino_prod,
                    codice_revisione: o.codice_revisione,
                    data_listino: o.data_listino,
                    data_validita: o.data_validita,
                    data_validita_rev: o.data_validita_rev,
                    descrizione_revisione: o.descrizione_revisione,
                },
            };
        default:
            return o;
    }
}

const multiValueFilters = [
    constants.BRAND_FILTER,
    constants.STATUS_FILTER,
    constants.MODEL_FILTER,
    constants.LINE_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
    constants.CUSTOM_FILTERS1,
    constants.CUSTOM_FILTERS2,
    constants.CUSTOM_FILTERS3,
    constants.CLASSE_CATEGORIA_SCONTO_VENDITA,
    constants.CLASSE_PRODOTTO,
    constants.TIPO_ARTICOLO,
    constants.CATEGORIA_MERCEOLOGICA,
];

// TODO: Refactor to make the code more DRY.
// This array is used in resetAllFilters to reset brand-related options when resetting all filters.
const brandRelatedFilters = [
    constants.STATUS_FILTER,
    constants.MODEL_FILTER,
    constants.LINE_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
    constants.CLASSE_CATEGORIA_SCONTO_VENDITA,
    constants.CLASSE_PRODOTTO,
    constants.TIPO_ARTICOLO,
    constants.CATEGORIA_MERCEOLOGICA,
]


function setFilterValue(state, action) {
    const {
        payload: { key, value, extra },
    } = action;
    //console.log('action => ', action)
    let newValue = value;

    if (isObject(value) && !isArray(value)) {
        newValue = OptionRecord(value);
    }

    if (multiValueFilters.includes(key)) {
        //console.warn('we are updating filter for brand ?', key)
        if (value === null) {
            //console.warn('is value null ?', value)
            return state.setIn([key, 'value'], List([]));
        }

        return state.updateIn([key, 'value'], (values) => {
            const idx = values.findIndex((item) => item.value === value.value);

            if (idx === -1) {
                return values.push(newValue);
            }

            return values.delete(idx);
        });
    }

    if (extra && extra.mode) {
        state = state.setIn([key, 'mode'], extra.mode);
    }

    return state.setIn([key, 'value'], newValue);
}

function setFilterOptions(state, action) {
    const {
        payload: { key, options },
    } = action;

    const newOptions = options.map((o) => OptionRecord(createOptionObject(key, o)));

    if (multiValueFilters.includes(key)) {
        const optionIds = newOptions.map((o) => o.value);

        state = state.updateIn([key, 'value'], (values) => {
            return values.filter((value) => {
                return optionIds.includes(value.value);
            });
        });
    }

    return state.setIn([key, 'options'], List(newOptions));
}

function enableFilters(state, action) {
    let result = state;

    action.payload.forEach((key) => {
        result = result.setIn([key, 'enabled'], true);
    });

    return result;
}

function disableFilters(state, action) {
    let result = state;

    action.payload.forEach((key) => {
        result = result.setIn([key, 'enabled'], false);
    });

    return result;
}

function resetFilters(state, action) {
    const res = action.payload.reduce((newState, key) => {
        const oldRecord = newState.get(key);

        let newRecord = oldRecord.clear().set('enabled', oldRecord.enabled);

        if (multiValueFilters.includes(key)) {
            newRecord = newRecord.set('value', List([]));
        }

        return newState.set(key, newRecord);
    }, state);

    return res;
}

function resetAllFilters(state) {

    return state.withMutations((mutableState) => {
        // Iterate over each key in the state
        Object.keys(state.toJS()).forEach((key) => {
            const oldRecord = state.get(key); // Get the current filter record

            // Log the key and its value before resetting
            // console.log('Resetting filter for key:', key, 'Current value:', oldRecord.toJS());

            let newRecord;

            // Check if it's a multi-value filter, reset 'value' to empty List for multi-value filters
            if (multiValueFilters.includes(key)) {
                //newRecord = oldRecord.set('value', List([]));
                newRecord = oldRecord
                    .set('value', List([]))
                    //.set('options', List([]));
            } else {
                // For single-value filters, reset to default value if available or log if unsure
                if (oldRecord.has('defaultValue')) {
                    newRecord = oldRecord.set('value', oldRecord.get('defaultValue'));
                } else {
                    if (key === 'priceRange') {
                        newRecord = oldRecord.set('value', [0, 0]) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else if (key === 'fullText') {
                        newRecord = oldRecord.set('value', '') // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else if (key === 'listini') {
                        newRecord = oldRecord.set('value', []) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    } else {
                        newRecord = oldRecord.set('value', null) // Keep the same value to avoid breaking the app
                        // console.log('Filter without defaultValue; preserving existing value:', key);
                    }
                    /*newRecord = oldRecord.set('value', oldRecord.get('value')) // Keep the same value to avoid breaking the app
                    console.log('Filter without defaultValue; preserving existing value:', key);*/
                }
            }

            if(brandRelatedFilters.includes(key)){
                newRecord = oldRecord
                    .set('value', List([]))
                    .set('options', List([]))
            }

            mutableState.set(key, newRecord); // Update state with reset filter

            // Log the new state after resetting
            // console.log('New filter state for key:', key, 'New value:', newRecord.toJS());
        });
    });
}

function setHaveFuoriListinoCartaceo(state, action) {
    return state.setIn(['haveFuoriListinoCartaceo', 'value'], action.payload)
}
function setBrandFilter(state, action) {
    return state.setIn([constants.BRAND_FILTER, 'value'], List([OptionRecord(action.payload)]));
}

function setPriceRange(state, action) {
    const { min, max } = action.payload[0]

    // Check the local storage for price values
    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))

    // If we have price saved in local storage, set the values
    if (storedFilters && storedFilters.prezzo_listino_from !== undefined && storedFilters.prezzo_listino_to !== undefined) {
        return state.updateIn([constants.PRICE_RANGE_FILTER], (filter) => {
            return filter
                .set('min', min)
                .set('max', max)
                .set('value', [min, max])  // Impostiamo il value solo se presente nel localStorage
        })
    }

    // If there isn't price stored in local values, we don't set the values because if the values are setted, the price range choosed is showed
    return state.updateIn([constants.PRICE_RANGE_FILTER], (filter) => {
        return filter.set('min', min).set('max', max)
    })
}


const handlers = {
    [actionTypes.SET_FILTER_VALUE]: setFilterValue,
    [actionTypes.SET_FILTER_OPTIONS]: setFilterOptions,
    [actionTypes.ENABLE_FILTERS]: enableFilters,
    [actionTypes.DISABLE_FILTERS]: disableFilters,
    [actionTypes.RESET_FILTERS]: resetFilters,
    [actionTypes.RESET_ALL_FILTERS]: resetAllFilters,
    [actionTypes.SET_PRICE_RANGE]: setPriceRange,
    [SET_FIXED_BRAND]: setBrandFilter,
    [actionTypes.SET_FUORI_CARTACEO_ENABLED]: setHaveFuoriListinoCartaceo,
};

export default function filtersReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

// marca: <uuid>
// stato: <uuid>
// serie: <uuid>
// linea: <uuid>
// q: <string>
// settore: <uuid>
// macrofamiglia: <uuid>
// famiglia: <uuid>
// include_accessori: true|false
// include_ricambi: true|false
// etim_class: <uuid>
// classification: etim|idrolab
// etim_features:
//     - id: <uuid>
//       type: alphanumeric
//       value: [<uuid>, <uuid>, ...]
//     - id: <uuid>
//       type: logic
//       value: true|false
//     - id: <uuid>
//       type: numeric
//       value: [<min>, <max>]
//     - id: <uuid>
//       type: range
//       value:
//         - [<float>, <float>]
//         - [<float>, <float>]
//         - ...
//     - ...
// page: <num> (solo per /articoli, verrà ignorata dai filtri)
// per_page: <num> (solo per /articoli, verrà ignorata dai filtri)
// sort: <string> (solo per /articoli, verrà ignorata dai filtri)
//     valori ammessi:
//         prezzo
//         codice_articolo
//         descrizione_articolo
//         modello
//         marca

// sort_direction: ASC|DESC (solo per /articoli, verrà ignorata dai filtri)
