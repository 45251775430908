import { put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel';
import * as filterSelectors from '../../selectors/filterSelectors';
import * as constants from '../../constants';
import { actions, setFilterValue } from '../../actions/filtersActions';
import { removeFilterFromLocalStorage } from '../../utils/LocalStorageFilterUtils';

function* loadFuoriCartaceoFilters(storedFilters) {
    emitSagaEvent('saga_start');
    console.log('saga_start from loadFuoriCartaceoFilters')
    // Check for 'fuori_listino_cartaceo' in stored filters
    const storedFuoriListinoCartaceo = storedFilters.hasOwnProperty('fuori_listino_cartaceo')
        ? storedFilters.fuori_listino_cartaceo
        : null;

    // If no valid stored value, end the saga early
    if (storedFuoriListinoCartaceo === null) {
        emitSagaEvent('saga_end');
        console.log('saga_end from loadFuoriCartaceoFilters')
        return;
    }

    // Retrieve the current filter options from the Redux state
    const fuoriCartaceoFilter = yield select(state => filterSelectors.getFilter(state, constants.FUORI_CARTACEO_FILTER));

    // Find the matching option based on stored value, converting to string as needed
    const storedOption = fuoriCartaceoFilter?.options.find(
        (option) => option.value === storedFuoriListinoCartaceo.toString()
    );

    // If a matching option is found, dispatch action to set the filter
    if (storedOption) {
        yield put(actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, storedOption));
    } else {
        console.warn(`No matching option found for fuori_listino_cartaceo value: ${storedFuoriListinoCartaceo}`);
    }

    emitSagaEvent('saga_end');
    console.log('saga_end from loadFuoriCartaceoFilters')
}

function* removeFuoriCartaceoFilter(storedFilters, key, value) {
    if(storedFilters['fuori_listino_cartaceo']){
        removeFilterFromLocalStorage('fuori_listino_cartaceo')
    }

    //yield put(setFilterValue(constants.FUORI_CARTACEO_FILTER, null))
}
export { loadFuoriCartaceoFilters, removeFuoriCartaceoFilter }