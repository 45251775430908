import { all, call, put } from 'redux-saga/effects'
import * as api from '../../api'
import * as constants from '../../constants'
import { actions } from '../../actions/filtersActions'

export function* loadCustomFiltersOptions(apiPayload) {
    const sigleMarca = apiPayload.sigle_marca
    const customFiltersApiPayload = apiPayload
    // Custom filters
    const [customFilter1Res, customFilter2Res, customFilter3Res] = yield all([
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CUSTOM_FILTERS1
        }),
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CUSTOM_FILTERS2
        }),
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CUSTOM_FILTERS3
        }),
    ])

    // Debug console.warn
    // console.warn('Custom Filter 1 => ', customFilter1Res.data)
    // console.warn('Custom Filter 2 => ', customFilter2Res.data)
    // console.warn('Custom Filter 3 => ', customFilter3Res.data)

    // Set custom filters
    yield put(actions.setFilterOptions(constants.CUSTOM_FILTERS1, customFilter1Res.data))
    yield put(actions.setFilterOptions(constants.CUSTOM_FILTERS2, customFilter2Res.data))
    yield put(actions.setFilterOptions(constants.CUSTOM_FILTERS3, customFilter3Res.data))
}

export function* loadCustomFiltersBrandRelatedOptions(apiPayload) {
    const sigleMarca = apiPayload.sigle_marca
    const customFiltersApiPayload = apiPayload

    // Custom filters
    const [classeCategoriaScontoVenditaRes, classeProdottoRes, tipoArticoloRes, categoriaMerceologicaRes] = yield all([
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CLASSE_CATEGORIA_SCONTO_VENDITA
        }),
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CLASSE_PRODOTTO
        }),
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.TIPO_ARTICOLO
        }),
        call(api.fetchCustomFilters, {
            ...customFiltersApiPayload,
            custom_filter: constants.CATEGORIA_MERCEOLOGICA
        }),
    ])

    // Debug console.warn
    // console.warn('Classe_categoria_sconto_vendita => ', classeCategoriaScontoVenditaRes.data)
    // console.warn('Classe_prodotto => ', classeProdottoRes.data)
    // console.warn('tipo_articolo => ', tipoArticoloRes.data)
    // console.warn('categoria_merceologica => ', categoriaMerceologicaRes.data)

    // Set custom filters
    yield put(actions.setFilterOptions(constants.CLASSE_CATEGORIA_SCONTO_VENDITA, classeCategoriaScontoVenditaRes.data))
    yield put(actions.setFilterOptions(constants.CLASSE_PRODOTTO, classeProdottoRes.data))
    yield put(actions.setFilterOptions(constants.TIPO_ARTICOLO, tipoArticoloRes.data))
    yield put(actions.setFilterOptions(constants.CATEGORIA_MERCEOLOGICA, categoriaMerceologicaRes.data))
}