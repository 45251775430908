import React from 'react'
import get from 'lodash/get'
import { formatKeyValue } from '../../../formatters'
import { getIntlNumberFormat, getLocaleFromLanguageCode, getLocalizedTextWithFallback } from '../../../intl-helpers';
import selectors from '../../../reducers/appReducer'
import { getDescrizioneMarcaFromArticolo } from '../../../helpers'
import { currenciesCodeISO } from '../../../currency-costants';
const intl = getIntlNumberFormat()

/**
 * Get international codes from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Formatted international codes or an empty string if not available.
 */
export const getCodiciInternazionali = (details) => {
    if (!get(details, 'identificazione.codici_internazionali.value')) {
        return ''
    }
    return formatKeyValue(details.identificazione.codici_internazionali.value)
}

/**
 * Get minimum quantity that can be purchased from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Formatted minimum quantity or an empty string if not available.
 */
export const getQtaMinimaAcquistabile = (details) => {
    if (
        get(details.dettagli_ordine, 'quantita_minima_acquistabile.value', null) !==
        null
    ) {
        return intl.format(
            details.dettagli_ordine.quantita_minima_acquistabile.value
        );
    }

    return '';
}

/**
 * Get unit of measure for packaging from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Localized unit of measure label or fallback text.
 */
export const getUmImballo = (details) => {
    return getLocalizedTextWithFallback(
        get(details, 'dettagli_ordine.unita_ordine.value.label', {}),
        getLocaleFromLanguageCode(selectors.getLanguage)
    )
}

/**
 * Get the quantity per packaging from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Formatted quantity or an empty string if not available.
 */
export const getQtaConfenzione = (details) => {
    if(!get(details, 'dettagli_ordine.unita_per_prezzo.value')) return ''

    return intl.format(details.dettagli_ordine.unita_per_prezzo.value);
}

/**
 * Get the status of the product from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Localized status label or fallback text.
 */
export const getStatus = (details) => {
    return getLocalizedTextWithFallback(
        get(details, 'identificazione.stato.value.label', {}),
        getLocaleFromLanguageCode(selectors.getLanguage)
    )
}

/**
 * Get the status code of the product from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string|null} - Status code or null if not available.
 */
export const getStatusCode = (details) => {
    return get(details, 'identificazione.stato.value.code', null)
}

/**
 * Get the start date of price validity from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string|undefined} - Start date of price validity or undefined if not available.
 */
export const getInizioValiditaPrezzo = (details) => {
    return get(details, 'prezzi[0].data_inizio_validita_prezzo.value');
}

/**
 * Get the brand description of the product from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Localized brand description.
 */
export const getMarcaArticolo = (details) => {
    const locale = getLocaleFromLanguageCode(selectors.getLanguage)
    return getDescrizioneMarcaFromArticolo(details, locale);
}

/**
 * Get the product description from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Localized product description or fallback text.
 */
export const getDescrizioneArticolo = (details) => {
    return getLocalizedTextWithFallback(
        get(details, 'identificazione.descrizione_articolo.value', {}),
        getLocaleFromLanguageCode(selectors.getLanguage)
    )
}

/**
 * Get the order unit from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string} - Localized order unit label or fallback text.
 */
export const getUnitaOrdine = (details) => {
    return getLocalizedTextWithFallback(
        get(details, 'dettagli_ordine.unita_ordine.value.label', {}),
        getLocaleFromLanguageCode(selectors.getLanguage)
    )
}
/**
 * Get the currency symbol from the currency properties
 * @param {Object} currency
 * @returns {string}
 */
export const getCurrencySymbol = (currency) => {
    if(!currency) return currenciesCodeISO.EUR.symbol

    if(currency.value === null) return currenciesCodeISO.EUR.symbol

    // console.warn('currency => ', currency)

    const currencyCode = currency.value.code

    return currenciesCodeISO[currencyCode].symbol
}

/**
 * Get the price of the product from the details object.
 * @param {Object} details - The details object containing product information.
 * @param {number} priceDecimalPrecision - The desired decimal precision for the price.
 * @returns {number|null} - Product price or null if not available.
 */

export const getPrezzo = (details, priceDecimalPrecision) => {
    let price = get(details, 'prezzi.0.prezzo_listino.value', null);

    if (priceDecimalPrecision === 0) {
        price = Math.trunc(price);
    }

    return price
}

/**
 * Get the product code from the details object.
 * @param {Object} details - The details object containing product information.
 * @returns {string|undefined} - Product code or undefined if not available.
 */
export const getCodiceArticolo = (details) => {
    return get(details, 'identificazione.codice_articolo.value')
}

/**
 * Get the label of a property from the product properties.
 * @param {string} key - The key of the property.
 * @param {Object} productProperties - The product properties object.
 * @returns {string} - Localized property label in lowercase or an empty string.
 */
export const getPropLabel = (key, productProperties) => {
    const locale = getLocaleFromLanguageCode(selectors.getLanguage)

    return get(
        productProperties,
        `${key}.label[${locale}]`,
        ''
    ).toLowerCase()
}

/**
 * Get the measures of the product from the details object.
 * @param {Object} details - The details object containing product information.
 * @param {Object} productProperties - The product properties object.
 * @returns {JSX.Element[]} - Array of table rows representing the product measures.
 */

export const getMisure = (details, productProperties) => {
    const filteredDetails = filterNonNullValues(details.misure)

    const locale = getLocaleFromLanguageCode(selectors.getLanguage)

    // console.warn('filterDetails => ', locale)

    /*return Object.keys(filteredDetails).map((key) => (
        <tr key={key}>
            <td>{getPropLabel(filteredDetails[key].property, productProperties)}</td>
            <td><strong>{typeof filteredDetails[key].value === 'object'
                ? filteredDetails[key].value.label.locale // Render a specific property of the object
                : filteredDetails[key].value}</strong></td>
        </tr>
    ));*/


    return Object.keys(filteredDetails).map((key) => {
        const detail = filteredDetails[key]
        const value = detail.value

        // Determines the display value based on the "value" structure
        let displayValue

        if (typeof value === 'object') {
            if (value?.label) {
                displayValue = value.label[locale] || JSON.stringify(value.label) // Uses "locale" if present
            } else if (value.amount && value.unit?.symbol) {
                displayValue = `${value.amount} ${value.unit.symbol}` // Shows "amount" with "unit.symbol" if present
            } else {
                displayValue = JSON.stringify(value) // Fallback: displays the object as JSON string
            }
        } else {
            displayValue = value // If not an object, uses the value directly
        }

        return (
            <tr key={key}>
                <td>{getPropLabel(detail.property, productProperties)}</td>
                <td><strong>{displayValue}</strong></td>
            </tr>
        )
    })
}

/**
 * Filter out properties with null values from an object.
 * @param {Object} obj - The object to filter.
 * @returns {Object} - Object containing only properties with non-null values.
 */
export const filterNonNullValues = (obj) => {
    const result = {};
    for (const key in obj) {
        if (obj[key].value !== null) {
            result[key] = obj[key];
        }
    }
    return result
}