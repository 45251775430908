// Selectors - Filter Selectors
import { getSelectsValues, nonFiltersKeys } from '../utils/selectors/filterSelectorUtils';
import * as constants from '../constants'
import omit from 'lodash/omit';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import defaults from 'lodash/defaults';
import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import { getApiPayload } from './filterPayload';
import without from 'lodash/without';

// Check if is used in other reducer/saga/compoent, if true, move in a utils file
const orderKeys = ['sort', 'sort_direction']

export const getState = (state) => state.filters

export const getFilter = (state, key) => state.filters[key]

export const getFilters = (state) => {
    const selects = getSelectsValues(state);
    return {
        ...selects,
        [constants.FULL_TEXT_FILTER]: state.filters[constants.FULL_TEXT_FILTER].value,
    }
}

export const getHaveFuoriListinoCartaceo = (state) => state.filters.get('haveFuoriListinoCartaceo').value

export const getActiveFiltersDetails = (state) => {
    let values = getSelectsValues(state);
    const filters = omit(state.filters.toJS(), ['haveFuoriListinoCartaceo'])
    const sectorFilter = sectorsSelectors.getFilter(state);
    const sectorSelected = sectorsSelectors.getSelectedTreeItemRecord(state);
    const isFixedBrand = appSelectors.getIsBrandFixed(state);

    filters.productClass = state.productClasses.productClassFilter.toJS()

    let sector = null;

    if (sectorSelected && sectorFilter.toJS().value.code !== 'etim') {
        sector = {
            taxonomy: sectorFilter.toJS().value,
            value: sectorSelected.toJS(),
        }
    }

    // console.warn('filters => ', filters)
    const selectKeys = Object.keys(values);

    const mergedFilters = defaults(values, filters, { sector })

    //console.warn('mergedFilters', mergedFilters)

    let result = transform(
        mergedFilters,
        (result, value, key) => {
            // console.log(key, value, isArray(value));

            const filterValues = filters[key]?.options;

            if (value === null) {
                result[key] = null;
            } else if (isArray(value)) {
                result[key] = value.map((optionValue) => {
                    return find(filterValues, { value: optionValue });
                });
            } else if (includes(selectKeys, key)) {
                result[key] = find(filterValues, { value });
            } else if (key === 'priceRange') {
                if (value.value[0] !== 0 || value.value[1] !== 0) {
                    result[key] = value.value;
                } else {
                    result[key] = null;
                }
            } else if (key === 'productClass') {
                result[key] = value.value;
            } else if (key === 'fullText') {
                if (value.value && value.value !== '') {
                    result[key] = value.value;
                } else {
                    result[key] = null;
                }
            } else if (key === 'fuoriCartaceo' || key === 'esportabilePerCatalogo') {
                result[key] = value.value;
            } else {
                result[key] = value;
            }
            /*else if (key === 'replacements' || key === 'optionals') {
                result[key] = value.value;
            }*/

            return result;
        },
        {}
    );

    if (isFixedBrand) {
        result = omit(result, ['brand']);
    }

    //console.warn('result => ', result)
    return result;
}

/**
 * Determines if any filter is active based on the API payload and application state.
 * create memoized selectors with createSelector imported from reselect.
 *
 * @function isAnyFilterActive
 * @param {Function} getApiPayload - Selector that returns the API payload, containing active filters as an object.
 * @param {Function} getIsBrandFixed - Selector that returns whether the brand filter is fixed (boolean).
 * @returns {boolean} - `true` if at least one filter is active, excluding non-filter keys; otherwise, `false`.
 */
export const isAnyFilterActive = createSelector(
    [getApiPayload, appSelectors.getIsBrandFixed],
    (payload, isBrandFixed) => {
        const filters = Object.keys(payload);

        let skipKeys = [];

        //console.warn('filters from isAnyFilterActive => ', filters)
        // console.log(without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys));

        // if (isBrandFixed) {
        //     skipKeys = ['marca'];
        // }

        return without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys).length > 0;
    }
)

export const isAnyFilterActiveOrOrderer = createSelector([getApiPayload], (payload) => {
    const filters = Object.keys(payload);

    return without(filters, ...nonFiltersKeys).length > 0;
})

export const hasOnlyBrandFilter = createSelector([getApiPayload], (payload) => {
    const filters = Object.keys(payload);

    if (!filters.includes('sigle_marca')) {
        return false;
    }

    let skipKeys = ['sigle_marca'];

    return without(filters, ...nonFiltersKeys, ...orderKeys, ...skipKeys).length === 0;
})