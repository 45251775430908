import { all, call, put, select } from 'redux-saga/effects'
import { emitSagaEvent } from '../sharedChannel'
import * as filterSelectors from '../../selectors/filterSelectors'
import * as constants from '../../constants'
import { actions, changeBrandFilterValue, setFilterValue } from '../../actions/filtersActions';
import { fetchFilterData } from '../filtersSaga'
import * as api from '../../api'
import { brandDependentFilters } from '../../utils/filtersUtils';
import {
    removeBrandRelatedFilterFromLocalStorage,
    removeFilterFromLocalStorage, updateLocalStorage
} from '../../utils/LocalStorageFilterUtils';
import { loadCustomFiltersBrandRelatedOptions } from '../filtersSaga/customFiltersSaga';
import { loadCustomFiltersBrandRelated } from './customFiltersSaga';

function* loadBrandFilters(storedFilters){
    emitSagaEvent('saga_start')
    const storedBrandCodes = storedFilters.sigle_marca || []

    // Get the current filters from Redux State
    const brandFilters = yield select(filterSelectors.getFilter, constants.BRAND_FILTER)

    // TODO: change this logic control place ?
    // if filters are setted up, we don't need to execute this function.
    if(brandFilters.value.toJS().length > 0){
        emitSagaEvent('saga_end')
        return
    }

    // Convert the options into JS array to easily make a check
    const brandOptions = brandFilters.get('options').toJS()
    // console.table('brandFilters.value.toJS() => ', brandFilters.value.toJS())

    // Iterate over brand filter values and select the existing ones
    const selectedBrands = brandOptions.filter(option => storedBrandCodes.includes(option.code))

    if (selectedBrands.length > 0) {
        for (const brand of selectedBrands) {
            yield put(actions.setFilterValue(constants.BRAND_FILTER, brand));
        }

        if (selectedBrands.length === 1) {
            yield all([
                call(fetchFilterData, api.fetchLineeFilter, storedFilters, constants.LINE_FILTER),
                call(fetchFilterData, api.fetchModelliFilter, storedFilters, constants.MODEL_FILTER),
                call(fetchFilterData, api.fetchSeriesFilter, storedFilters, constants.SERIES_FILTER),
                call(fetchFilterData, api.fetchListini, storedFilters, constants.LISTINI_FILTER),
                call(loadCustomFiltersBrandRelatedOptions, storedFilters)
            ]);

            yield put(actions.enableFilters(brandDependentFilters));
        }
    }

    // Load the values
    yield call(loadValues, storedFilters)

    emitSagaEvent('saga_end')
}

function* loadValues(storedFilters){

    if(storedFilters?.linea){
        const lineaFilterOptions = yield select(filterSelectors.getFilter, constants.LINE_FILTER)

        const lineaFilters = storedFilters.linea

        for  (const linea of lineaFilters){
            const matchedOption = lineaFilterOptions.options.find(option => option.value === linea)

            yield put(setFilterValue(constants.LINE_FILTER, matchedOption))
        }
    }

    if(storedFilters?.modello){
        const modelFilterOptions = yield select(filterSelectors.getFilter, constants.MODEL_FILTER)

        const modelFilters = storedFilters.modello

        for  (const model of modelFilters){
            const matchedOption = modelFilterOptions.options.find(option => option.value === model)

            yield put(setFilterValue(constants.MODEL_FILTER, matchedOption))
        }
    }

    if(storedFilters?.serie){
        const seriesFilterOptions = yield select(filterSelectors.getFilter, constants.SERIES_FILTER)

        const seriesFilters = storedFilters.serie

        for  (const serie of seriesFilters){
            const matchedOption = seriesFilterOptions.options.find(option => option.value === serie)

            yield put(setFilterValue(constants.SERIES_FILTER, matchedOption))
        }
    }

    if(storedFilters?.listini){
        const listiniFilterOptions = yield select(filterSelectors.getFilter, constants.LISTINI_FILTER)

        const listiniFilters = storedFilters.listini

        for  (const listino of listiniFilters){
            const matchedOption = listiniFilterOptions.options.find(option => option.value === listino)

            yield put(setFilterValue(constants.LISTINI_FILTER, matchedOption))
        }
    }

    if(storedFilters?.custom_filters){
        yield call(loadCustomFiltersBrandRelated, storedFilters)
    }


}

// Handle removal brand filter: we need to check if the brand is only one,
// if true, remove all related filters from local storage
function* removeBrandFilter(value){
    const actualBrandFilters = yield select(filterSelectors.getFilter, constants.BRAND_FILTER)

    const actualBrandFilterValues = actualBrandFilters?.value?.toJS() || []

    if(value === null){
        removeBrandRelatedFilterFromLocalStorage()
        // we remove fuori cartaceo
        removeFilterFromLocalStorage('fuori_listino_cartaceo')
        removeFilterFromLocalStorage('brand')

        return
    }

    let isValueInFilter = false;

    isValueInFilter = actualBrandFilterValues.some(
        (filter) => filter?.value === value
    )

    if (!isValueInFilter) {

        removeBrandRelatedFilterFromLocalStorage()
        // we remove fuori cartaceo
        removeFilterFromLocalStorage('fuori_listino_cartaceo')
    } else if (actualBrandFilterValues.length === 1 && isValueInFilter) {

        removeBrandRelatedFilterFromLocalStorage()
        // we remove fuori cartaceo
        removeFilterFromLocalStorage('fuori_listino_cartaceo')
        removeFilterFromLocalStorage('brand')

    }else {
        // Case 3: The value is present but is not the only one, toggle without resetting
        // in localstorage brands are saved as apipayload, so sigle_marca. The value stored
        // is the code, like "BRE", "ACC" and so on
        const selectedBrand = actualBrandFilterValues.find(option => option.value === value);
        const brandCode = selectedBrand?.code
        // console.warn('brandCode => ', brandCode)
        updateLocalStorage('sigle_marca', brandCode, 'remove')
    }
}

// Handle removal brand related filters
function* removeBrandRelatedFilter(storedFilters, filter, key, value){
    if (storedFilters[filter]) {
        console.warn('debug removeBrandRelatedFilter', filter, key, value)
        // If passed value is null, remove the filter
        if(!value) delete storedFilters[filter]

        // If passed filter is an array, remove only the passed value
        if (Array.isArray(storedFilters[filter])) {
            storedFilters[filter] = storedFilters[filter].filter(item => item !== value);

            // If passed filter is an array and is empty, remove also the key from storedFilters
            if (storedFilters[filter].length === 0) {
                delete storedFilters[filter];
            }
        } else {
            // If passed filter is an array and have only one value, remove also the key from storedFilters
            delete storedFilters[filter];
        }

        // Update or delete the entire array from localStorage by its key
        if (Object.keys(storedFilters).length === 0) {
            localStorage.removeItem('userFilters');
        } else {
            localStorage.setItem('userFilters', JSON.stringify(storedFilters));
        }
    }
}
export { loadBrandFilters, removeBrandRelatedFilter, removeBrandFilter}