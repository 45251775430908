import * as c from '../../../constants';
import { getAssetURL } from '../../../api';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { getDetailValue } from '../../../utils/dataFormaters';
import find from 'lodash/find';
import remove from 'lodash/remove';
import { getEtimLabel, getEtimValue } from './utils';
import { getLocaleFromLanguageCode } from '../../../intl-helpers';
import selectors from '../../../reducers/appReducer'
const locale = getLocaleFromLanguageCode(selectors.getLanguage)

export const getAttributes = (parameters) => {
    const {
        category,
        details,
        productProperties,
        etimClassDetails,
        i18n,
        priceDecimalPrecision,
    } = parameters

    let res = []

    // FILES AND IMAGES
    if (category.type === c.SECTION_TYPE_FILES || category.type === c.SECTION_TYPE_IMAGES) {

        let res = Object.values(details.assets[category.key]).map((asset) => {
            let src = getAssetURL(encodeURIComponent(asset.url));

            return {
                asset_classes: asset.asset_classes,
                label: asset.asset_classes[0] ? asset.asset_classes[0].label[locale] : '-',
                // FIXME: gestire fallback per descrizione se non presente locale
                description: asset.description ? asset.description[locale] : '-',
                size: asset.size,
                filename: asset.url,
                ext: asset.extension,
                src: src.replaceAll('%2F', '/'), // Richiesto da Enea il 14/07/2023 sostituire il carattere encodato %2F con / altrimenti i link
                // non funzionano in determinate situazioni (reverse proxy)
            };
        });

        return sortBy(res, 'label');
    }

    // FEATURES
    if (category.isFeatures) {
        // console.log(details);

        res = Object.values(details.etim.etim_features).map((feature) => {

            return {
                key: feature.id,
                label: getEtimLabel(etimClassDetails.etim_features, feature.id, locale),
                value: getEtimValue(etimClassDetails.etim_features, feature, locale, i18n),
            };
        });

        res = sortBy(res, 'label');

        if (details.etim.id !== null) {
            res.unshift({
                key: 'klass',
                label: i18n._('etim class'),
                value: get(details, `etim.name`) + ' - ' + get(details, `etim.label.${locale}`),
            });
        }

        return res
    }

    // CORRELATI
    if (category.key === c.SECTION_KEY_CORRELATI) {
        return details[category.key];
    }

    // LISTINI
    if (category.key === c.SECTION_KEY_LISTINI) {
        if (!isArray(details[category.key])) {
            return []
        }

        return details[category.key].map((listino) => {
            return Object.values(listino).reduce((obj, prop) => {
                obj[prop.property] = prop.value
                return obj
            }, {})
        })
    }

    // REPEATABLE
    if (category.type === c.SECTION_TYPE_REPEATABLE) {
        const categoryData = get(details, category.key, [])

        res = categoryData.map((row, i) => {
            return Object.values(row).map((prop) => {
                const productProp = productProperties[prop.property]

                return {
                    key: prop.property,
                    label: productProp.label[locale],
                    value: getDetailValue(
                        prop,
                        productProp,
                        locale,
                        productProperties,
                        i18n,
                        priceDecimalPrecision
                    ),
                }
            })
        })

        return res
    }

    //console.warn('details from getAttributes => ', details[category.key])

    // DEFAULTS ? CATEGORIES
    if (details[category.key]) {

        let categoryDetail = details[category.key];

        if (
            Object.keys(details[category.key]).length === 1 &&
            details[category.key][Object.keys(details[category.key])[0]].children
                .length > 0
        ) {
            categoryDetail =
                details[category.key][Object.keys(details[category.key])].children;
        }

        res = Object.values(categoryDetail).map((prop) => {
            // console.warn(prop);
            const productProp = productProperties[prop.property];

            if (!productProp) {
                return {
                    key: prop.property,
                    label: 'n.d.',
                    value: null,
                }
            }

            let value = getDetailValue(
                prop,
                productProp,
                locale,
                productProperties,
                i18n,
                priceDecimalPrecision
            )

            // FIXME: workaround richiesto per riportare il valore della sigla marca se la descrizione marca é nulla
            if (prop.property === 'descrizione_marca' && value === null) {
                value = get(
                    details,
                    `[${category.key}].sigla_marca.value.label[${locale}]`
                )
            }

            return {
                key: prop.property,
                label: productProp.label[locale],
                value,
            }
        })
    }

    const taxonomy = find(res, { key: 'idrolab_taxonomy' });

    if (taxonomy && taxonomy.value) {
        res.push({
            key: 'idrolab_taxonomy_sector',
            label: i18n._('sector'),
            value:
                taxonomy.value.sector.code +
                ' - ' +
                get(taxonomy, `value.sector.label[${locale}]`, ''),
        });

        res.push({
            key: 'idrolab_taxonomy_macrofamily',
            label: i18n._('macrofamily'),
            value:
                taxonomy.value.macrofamily.code +
                ' - ' +
                get(taxonomy, `value.macrofamily.label[${locale}]`, ''),
        });

        res.push({
            key: 'idrolab_taxonomy_family',
            label: i18n._('family'),
            value:
                taxonomy.value.family.code +
                ' - ' +
                get(taxonomy, `value.family.label[${locale}]`, ''),
        });

        remove(res, { key: 'idrolab_taxonomy' })
    }

    return res
}