export const currenciesCodeISO = {
    AED: { name: "Dirham degli Emirati Arabi Uniti", symbol: "د.إ" },
    AFN: { name: "Afghani afghano", symbol: "؋" },
    ALL: { name: "Lek albanese", symbol: "L" },
    AMD: { name: "Dram armeno", symbol: "֏" },
    ANG: { name: "Fiorino delle Antille Olandesi", symbol: "ƒ" },
    AOA: { name: "Kwanza angolano", symbol: "Kz" },
    ARS: { name: "Peso argentino", symbol: "$" },
    AUD: { name: "Dollaro australiano", symbol: "$" },
    AWG: { name: "Fiorino di Aruba", symbol: "ƒ" },
    AZN: { name: "Manat azero", symbol: "₼" },
    BAM: { name: "Marco convertibile bosniaco", symbol: "KM" },
    BBD: { name: "Dollaro di Barbados", symbol: "$" },
    BDT: { name: "Taka bengalese", symbol: "৳" },
    BGN: { name: "Lev bulgaro", symbol: "лв" },
    BHD: { name: "Dinaro del Bahrein", symbol: ".د.ب" },
    BIF: { name: "Franco burundese", symbol: "FBu" },
    BMD: { name: "Dollaro delle Bermuda", symbol: "$" },
    BND: { name: "Dollaro del Brunei", symbol: "$" },
    BOB: { name: "Boliviano", symbol: "Bs." },
    BRL: { name: "Real brasiliano", symbol: "R$" },
    BSD: { name: "Dollaro delle Bahamas", symbol: "$" },
    BTN: { name: "Ngultrum bhutanese", symbol: "Nu." },
    BWP: { name: "Pula botswana", symbol: "P" },
    BYN: { name: "Rublo bielorusso", symbol: "Br" },
    BZD: { name: "Dollaro del Belize", symbol: "$" },
    CAD: { name: "Dollaro canadese", symbol: "$" },
    CDF: { name: "Franco congolese", symbol: "FC" },
    CHF: { name: "Franco svizzero", symbol: "CHF" },
    CLP: { name: "Peso cileno", symbol: "$" },
    CNY: { name: "Yuan cinese", symbol: "¥" },
    COP: { name: "Peso colombiano", symbol: "$" },
    CRC: { name: "Colón costaricano", symbol: "₡" },
    CUP: { name: "Peso cubano", symbol: "$" },
    CVE: { name: "Escudo capoverdiano", symbol: "$" },
    CZK: { name: "Corona ceca", symbol: "Kč" },
    DDK: { name: "Corona danese", symbol: "kr" }, // Isn't the standard ISO, added because i have brand 001 with wrong Corona Danese CODE
    DJF: { name: "Franco gibutiano", symbol: "Fdj" },
    DKK: { name: "Corona danese", symbol: "kr" },
    DOP: { name: "Peso dominicano", symbol: "$" },
    DZD: { name: "Dinaro algerino", symbol: "د.ج" },
    EGP: { name: "Sterlina egiziana", symbol: "£" },
    ERN: { name: "Nakfa eritreo", symbol: "Nfk" },
    ETB: { name: "Birr etiope", symbol: "Br" },
    EUR: { name: "Euro", symbol: "€" },
    FJD: { name: "Dollaro delle Figi", symbol: "$" },
    FKP: { name: "Sterlina delle Falkland", symbol: "£" },
    FOK: { name: "Corona delle Fær Øer", symbol: "kr" },
    GBP: { name: "Sterlina britannica", symbol: "£" },
    GEL: { name: "Lari georgiano", symbol: "₾" },
    GGP: { name: "Sterlina di Guernsey", symbol: "£" },
    GHS: { name: "Cedi ghanese", symbol: "₵" },
    GIP: { name: "Sterlina di Gibilterra", symbol: "£" },
    GMD: { name: "Dalasi gambiano", symbol: "D" },
    GNF: { name: "Franco guineano", symbol: "FG" },
    GTQ: { name: "Quetzal guatemalteco", symbol: "Q" },
    GYD: { name: "Dollaro della Guyana", symbol: "$" },
    HKD: { name: "Dollaro di Hong Kong", symbol: "$" },
    HNL: { name: "Lempira honduregna", symbol: "L" },
    HRK: { name: "Kuna croata", symbol: "kn" },
    HTG: { name: "Gourde haitiano", symbol: "G" },
    HUF: { name: "Fiorino ungherese", symbol: "Ft" },
    IDR: { name: "Rupia indonesiana", symbol: "Rp" },
    ILS: { name: "Nuovo siclo israeliano", symbol: "₪" },
    IMP: { name: "Sterlina di Man", symbol: "£" },
    INR: { name: "Rupia indiana", symbol: "₹" },
    IQD: { name: "Dinaro iracheno", symbol: "ع.د" },
    IRR: { name: "Rial iraniano", symbol: "﷼" },
    ISK: { name: "Corona islandese", symbol: "kr" },
    JEP: { name: "Sterlina di Jersey", symbol: "£" },
    JMD: { name: "Dollaro giamaicano", symbol: "$" },
    JOD: { name: "Dinaro giordano", symbol: "د.ا" },
    JPY: { name: "Yen giapponese", symbol: "¥" },
    KES: { name: "Scellino keniota", symbol: "Sh" },
    KGS: { name: "Som kirghiso", symbol: "с" },
    KHR: { name: "Riel cambogiano", symbol: "៛" },
    KID: { name: "Dollaro di Kiribati", symbol: "$" },
    KMF: { name: "Franco delle Comore", symbol: "CF" },
    KRW: { name: "Won sudcoreano", symbol: "₩" },
    KWD: { name: "Dinaro kuwaitiano", symbol: "د.ك" },
    KYD: { name: "Dollaro delle Cayman", symbol: "$" },
    KZT: { name: "Tenge kazako", symbol: "₸" },
    LAK: { name: "Kip laotiano", symbol: "₭" },
    LBP: { name: "Lira libanese", symbol: "ل.ل" },
    LKR: { name: "Rupia di Sri Lanka", symbol: "Rs" },
    LRD: { name: "Dollaro liberiano", symbol: "$" },
    LSL: { name: "Loti del Lesotho", symbol: "L" },
    LYD: { name: "Dinaro libico", symbol: "ل.د" },
    MAD: { name: "Dirham marocchino", symbol: "د.م." },
    MDL: { name: "Leu moldavo", symbol: "L" },
    MGA: { name: "Ariary malgascio", symbol: "Ar" },
    MKD: { name: "Denar macedone", symbol: "ден" },
    MMK: { name: "Kyat birmano", symbol: "K" },
    MNT: { name: "Tugrik mongolo", symbol: "₮" },
    MOP: { name: "Pataca di Macao", symbol: "MOP$" },
    MRU: { name: "Ouguiya mauritana", symbol: "UM" },
    MUR: { name: "Rupia mauriziana", symbol: "₨" },
    MVR: { name: "Rufiyaa maldiviana", symbol: "Rf" },
    MWK: { name: "Kwacha malawiano", symbol: "MK" },
    MXN: { name: "Peso messicano", symbol: "$" },
    MYR: { name: "Ringgit malese", symbol: "RM" },
    MZN: { name: "Metical mozambicano", symbol: "MT" },
    NAD: { name: "Dollaro della Namibia", symbol: "$" },
    NGN: { name: "Naira nigeriana", symbol: "₦" },
    NIO: { name: "Córdoba nicaraguense", symbol: "C$" },
    NOK: { name: "Corona norvegese", symbol: "kr" },
    NPR: { name: "Rupia nepalese", symbol: "Rs" },
    NZD: { name: "Dollaro neozelandese", symbol: "$" },
    OMR: { name: "Rial omanita", symbol: "ر.ع." },
    PAB: { name: "Balboa panamense", symbol: "B/." },
    PEN: { name: "Sol peruviano", symbol: "S/." },
    PGK: { name: "Kina papuana", symbol: "K" },
    PHP: { name: "Peso filippino", symbol: "₱" },
    PKR: { name: "Rupia pakistana", symbol: "₨" },
    PLN: { name: "Zloty polacco", symbol: "zł" },
    PYG: { name: "Guaraní paraguaiano", symbol: "₲" },
    QAR: { name: "Rial del Qatar", symbol: "ر.ق" },
    RON: { name: "Leu rumeno", symbol: "lei" },
    RSD: { name: "Dinaro serbo", symbol: "дин" },
    RUB: { name: "Rublo russo", symbol: "₽" },
    RWF: { name: "Franco ruandese", symbol: "FRw" },
    SAR: { name: "Riyal saudita", symbol: "﷼" },
    SBD: { name: "Dollaro delle Isole Salomone", symbol: "$" },
    SCR: { name: "Rupia delle Seychelles", symbol: "₨" },
    SDG: { name: "Sterlina sudanese", symbol: "ج.س." },
    SEK: { name: "Corona svedese", symbol: "kr" },
    SGD: { name: "Dollaro di Singapore", symbol: "$" },
    SHP: { name: "Sterlina di Sant'Elena", symbol: "£" },
    SLL: { name: "Leone della Sierra Leone", symbol: "Le" },
    SOS: { name: "Scellino somalo", symbol: "Sh" },
    SRD: { name: "Dollaro del Suriname", symbol: "$" },
    SSP: { name: "Sterlina sudsudanese", symbol: "£" },
    STN: { name: "Dobra di São Tomé", symbol: "Db" },
    SYP: { name: "Lira siriana", symbol: "£" },
    SZL: { name: "Lilangeni dello Swaziland", symbol: "L" },
    THB: { name: "Baht thailandese", symbol: "฿" },
    TJS: { name: "Somoni tagiko", symbol: "ЅМ" },
    TMT: { name: "Manat turkmeno", symbol: "m" },
    TND: { name: "Dinaro tunisino", symbol: "د.ت" },
    TOP: { name: "Paʻanga tongano", symbol: "T$" },
    TRY: { name: "Lira turca", symbol: "₺" },
    TTD: { name: "Dollaro di Trinidad e Tobago", symbol: "$" },
    TWD: { name: "Nuovo dollaro taiwanese", symbol: "NT$" },
    TZS: { name: "Scellino tanzaniano", symbol: "Sh" },
    UAH: { name: "Grivnia ucraina", symbol: "₴" },
    UGX: { name: "Scellino ugandese", symbol: "Sh" },
    USD: { name: "Dollaro statunitense", symbol: "$" },
    UYU: { name: "Peso uruguaiano", symbol: "$U" },
    UZS: { name: "Sum uzbeko", symbol: "лв" },
    VES: { name: "Bolívar venezuelano", symbol: "Bs." },
    VND: { name: "Dong vietnamita", symbol: "₫" },
    VUV: { name: "Vatu di Vanuatu", symbol: "VT" },
    WST: { name: "Tala samoano", symbol: "T" },
    XAF: { name: "Franco CFA BEAC", symbol: "FCFA" },
    XCD: { name: "Dollaro dei Caraibi Orientali", symbol: "$" },
    XOF: { name: "Franco CFA BCEAO", symbol: "CFA" },
    XPF: { name: "Franco CFP", symbol: "₣" },
    YER: { name: "Rial yemenita", symbol: "﷼" },
    ZAR: { name: "Rand sudafricano", symbol: "R" },
    ZMW: { name: "Kwacha zambiano", symbol: "ZK" },
    ZWL: { name: "Dollaro dello Zimbabwe", symbol: "$" }
}

