import { actionTypes } from '../actions/sectorsActions';
import { Record, List, Map, fromJS, isKeyed, Seq } from 'immutable';
import TreeUtils from 'immutable-treeutils';
// import { i18nMark } from '@lingui/react';
// import sortBy from 'lodash/sortBy';

import { SelectFilterRecord, OptionRecord } from './helpers';
import { DEFAULT_CLASSIFICATION, ETIM_CLASSIFICATION } from '../constants';
// import { ETIM_CLASSIFICATION, IDROLAB_CLASSIFICATION } from '../constants';

const classificationRecord = SelectFilterRecord({
    enabled: true,
    options: List([]),
    value: null,
});

export const NodeRecord = Record({
    id: null,
    name: null,
    code: null,
    filterParams: Map({}),
    // count: 0,
    children: List([]),
});

const StateRecord = Record({
    classificationFilter: classificationRecord,
    tree: List([]),
    productTreeRoots: Map({}),
    selectedItem: null,
    // idrolabClassificationId: null,
    // idrolabClassificationType: null,
    isFetching: false,
    currentApiHash: null,
});

// function getCreateTreeFn(state) {
//     return state.classificationFilter.value.value === ETIM_CLASSIFICATION
//         ? createEtimTree
//         : createIdroLABTree;
// }

/*function createEtimTree(raw = []) {
    // FIXME: come ordinare i risultati dell'albero?
    return fromJS(raw, (key, value) => {
        if (!isKeyed(value)) {
            return value.toList();
        }

        if (value.get('id')) {
            return NodeRecord({
                id: value.get('id'),
                name: value.get('description'),
                code: value.get('name'),
                children: value.get('etim_classes')
            });
        }

        return value.toMap();
    });

    // return fromJS(sortBy(raw, 'descrizione'), (key, value) => {
    //     return isKeyed(value)
    //         ? NodeRecord({
    //               id: value.get('id'),
    //               name: value.get('description'),
    //               children: value.get('etim_classes')
    //                   ? value.get('etim_classes').sortBy(c => c.name)
    //                   : value.get('etim_classes')
    //           })
    //         : value.toList();
    // });
}*/

function createProductTree(raw = []) {
    // FIXME: come ordinare i risultati dell'albero?
    return fromJS(raw, (key, value) => {
        if (!isKeyed(value)) {
            return value.toList();
        }

        // devo usare una chiave che esista solo nella root di un elemento e non come figlio di una delle sue proprietà
        if (value.get('type')) {
            // console.log(value.toJS());

            return NodeRecord({
                id: value.get('code'),
                name: value.get('label'),
                code: value.get('data').get('code'),
                filterParams: value.get('filter_params'),
                children: value.get('children'),
            });
        }

        return value.toMap();
    });
}

export const DEFAULT_STATE = StateRecord();

function setTree(state, action) {
    // const createTreeFn = getCreateTreeFn(state);

    return state.set('tree', createProductTree(action.payload));
}

function initProductTreeRoots(state, action) {
    const options = fromJS(
        action.payload.map((root) => {
            return OptionRecord({
                label: root.label,
                value: root.code,
                code: root.code,
            });
        })
    );

    let defaultOption = options.find((o) => o.code === DEFAULT_CLASSIFICATION);

    if (!defaultOption) {
        defaultOption = options.find((o) => o.code === ETIM_CLASSIFICATION);
    }

    if (!defaultOption) {
        defaultOption = options.first();
    }

    const productTreeRoots = fromJS(
        action.payload.reduce((productRoots, root) => {
            productRoots[root.code] = root;

            return productRoots;
        }, {})
    );

    return state
        .setIn(['productTreeRoots'], productTreeRoots)
        .setIn(['classificationFilter', 'options'], options)
        .setIn(['classificationFilter', 'value'], defaultOption);
}

function toggleTreeItem(state, action) {
    // console.log('++++ toggleTreeItem', action.payload)
    // console.log('++++ toggleTreeItem, selected item', state.get('selectedItem'))
    // console.trace('TOGGLE_TREE_ITEM trace');
    return state.set(
        'selectedItem',
        state.get('selectedItem') === action.payload.id ? null : action.payload.id
    );
}

/*function toggleIdrolabClassification(state, action) {
    const { id, type } = action.payload;

    if (state.get('idrolabClassificationId') === id) {
        return state.merge({
            idrolabClassificationId: null,
            idrolabClassificationType: null
        });
    }

    return state.merge({
        idrolabClassificationId: id,
        idrolabClassificationType: type
    });
}*/

/*function resetIdrolabClassification(state) {
    return state.merge({
        idrolabClassificationId: null,
        idrolabClassificationType: null
    });
}*/

function selectTreeItem(state, action) {
    return state.set('selectedItem', action.payload);
}

function resetSelectedTreeItem(state) {
    return state.set('selectedItem', null);
}

function fetchStart(state, action) {
    return state.set('isFetching', true);
}

function fetchSuccess(state, action) {
    // const createTreeFn = getCreateTreeFn(state);

    return state.set('tree', createProductTree(action.payload)).set('isFetching', false);
}

function fetchFail(state, action) {
    return state.set('isFetching', false);
}

function setClassificationValue(state, action) {
    return state.setIn(
        ['classificationFilter', 'value'],
        action.payload ? OptionRecord(action.payload) : null
    );
}

function setApiHash(state, action) {
    return state.set('currentApiHash', action.payload);
}

function resetClassification(state) {
    // Use withMutations to batch updates for better performance
    return state.withMutations((mutableState) => {
        // Get the classification filter from the mutable state
        const classificationFilter = mutableState.get('classificationFilter')

        // Log current state for debugging
        // console.log('classificazione reset');
        // console.log(classificationFilter.toJS());

        // Reset the selectedItem to null
        mutableState.set('selectedItem', null);
        // console.log('selectedItem reset to null');

        // Define the default classification (based on your preference, or use the first option)
        const defaultOption = classificationFilter.get('options').find((option) => {
            return option.get('value') === 'idrolab';  // Replace 'idrolab' with desired default option
        }) || classificationFilter.get('options').first();  // Fallback to first option if 'idrolab' is not found

        // Reset the classification filter
        const resetFilter = classificationFilter
            .set('value', defaultOption)  // Set the value to the default option
            .set('enabled', true)  // Set enabled to false (you can adjust this as needed)
            .set('options', classificationFilter.get('options'));  // Retain the options

        // Apply the reset classification filter to the state
        mutableState.set('classificationFilter', resetFilter);

        // Log final state for debugging
        // console.log('classificationFilter after reset:', mutableState.get('classificationFilter').toJS());
    });
}

function resetClassificationFilters(state) {
    //TODO


}

// HANDLERS
const handlers = {
    [actionTypes.SET_TREE]: setTree,
    [actionTypes.INIT_PRODUCT_TREE_ROOTS]: initProductTreeRoots,
    [actionTypes.TOGGLE_TREE_ITEM]: toggleTreeItem,
    // [actionTypes.TOGGLE_IDROLAB_CLASSIFICATION]: toggleIdrolabClassification,
    // [actionTypes.RESET_IDROLAB_CLASSIFICATION]: resetIdrolabClassification,
    // [actionTypes.TOGGLE_CLASS]: toggleTreeItem,
    // [actionTypes.TOGGLE_GROUP]: toggleTreeItem,
    [actionTypes.SET_SELECTED_ITEM_TREE]: selectTreeItem,
    [actionTypes.RESET_SELECTED_TREE_ITEM]: resetSelectedTreeItem,
    [actionTypes.FETCH_SECTORS_START]: fetchStart,
    [actionTypes.FETCH_SECTORS_SUCCESS]: fetchSuccess,
    [actionTypes.FETCH_SECTORS_FAIL]: fetchFail,
    [actionTypes.SET_CLASSIFICATION_VALUE]: setClassificationValue,
    [actionTypes.SET_API_HASH]: setApiHash,
    [actionTypes.RESET_CLASSIFICATION]: resetClassification,
    [actionTypes.RESET_CLASSIFICATION_FILTERS]: resetClassificationFilters,
};

export default function sectorsReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

export const treeUtils = new TreeUtils(Seq(), 'id', 'children');

export const selectors = {
    getTree(state) {
        return state.sectors.tree;
    },
    getSelectedItem(state) {
        //console.log('getSelectedItem called in sectorsReducer, we have selectedItem', state.sectors.selectedItem)
        return state.sectors.selectedItem;
    },
    /*getIdrolabClassification(state) {
        if (state.sectors.idrolabClassificationId === null) {
            return null;
        }

        return {
            id: state.sectors.idrolabClassificationId,
            type: state.sectors.idrolabClassificationType
        };
    },*/
    getSelectedTreeItemRecord(state) {
        // Aggiungo un nodo root fittizio per permettere di utilizzare immutable-treeutils
        const tree = NodeRecord({
            id: 'root',
            children: state.sectors.tree,
        });

        const node = treeUtils.byId(tree, state.sectors.selectedItem);

        if (node) {
            // console.warn(treeUtils.depth(tree, state.sectors.selectedItem));
            return tree.getIn(node);
        }

        return null;
    },
    getSelectedTreeItemDepth(state) {
        // Aggiungo un nodo root fittizio per permettere di utilizzare immutable-treeutils
        const tree = NodeRecord({
            id: 'root',
            children: state.sectors.tree,
        });

        const node = treeUtils.byId(tree, state.sectors.selectedItem);

        if (node) {
            return treeUtils.depth(tree, node);
        }

        return null;
    },
    getIsFetching(state) {
        return state.sectors.isFetching;
    },
    getFilter(state) {
        return state.sectors.classificationFilter;
    },
    getFilterValue(state) {
        return state.sectors.classificationFilter.value
            ? state.sectors.classificationFilter.value.value
            : null;
    },
    getCurrentApiHash(state) {
        return state.sectors.currentApiHash;
    },
};
