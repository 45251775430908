import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';

import MatchWhenRole from './routing/MatchWhenRole';
import Loader from './utils/Loader';
import { getUserGroups /*, createGroup*/ } from '../api';
import UserGroupEditModal from './UserGroupEditModal';
import { setUserGroups } from '../actions/appActions';
import { getFromInternalPage, getLoadedFromOtherPages } from '../reducers/pageStateReducer'
import { setIsLoadingFromLocalStorage, setLoadedFromOthersPages } from '../actions/pageStateActions';

export class UserGroupsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            groups: [],
            showModal: false,
            currentGroupId: null,
        };
    }

    async componentDidMount() {
        this.props.dispatch(setIsLoadingFromLocalStorage(false))

        const groups = await this.fetchData();

        this.setState({
            isFetching: false,
            groups,
        });
    }

    componentWillUnmount() {
        this.props.dispatch(setLoadedFromOthersPages(true))
    }

    async fetchData() {
        const res = await getUserGroups();

        return res.data.data;
    }

    handleNew = () => {
        this.setState({
            showModal: true,
            currentGroupId: 'new',
        });
    };

    handleShowDetails(id) {
        this.setState({
            showModal: true,
            currentGroupId: id,
        });
    }

    closeDetails = async () => {
        this.setState({
            showModal: false,
            currentGroupId: null,
        });

        const groups = await this.fetchData();

        this.props.dispatch(setUserGroups({ data: groups }));

        this.setState({
            groups,
        });
    };

    getCurrentGroup() {
        if (this.state.currentGroupId === 'new') {
            return {
                id: null,
            };
        }

        return this.state.groups.find((g) => g.id === this.state.currentGroupId);
    }

    render() {
        const { isFetching, groups, showModal } = this.state;

        return (
            <Fragment>
                {showModal && (
                    <UserGroupEditModal
                        onClose={this.closeDetails}
                        group={this.getCurrentGroup()}
                    />
                )}
                <div className="mx-1">
                    <div className="grid grid-cols-12 gap-1">
                        <div className="col-span-full text-center mt-2">
                            <h2 className="text-primary">
                                <Trans id="User Groups management" />
                            </h2>
                        </div>
                        <div className="col-span-full my-2 text-right">
                            <button className="btn btn-primary" onClick={this.handleNew}>
                                <i className="icon icon-plus" /> <Trans id="group:new" />
                            </button>
                        </div>
                        <div className="col-span-full">
                            {isFetching ? (
                                <Loader />
                            ) : (
                                <table className="table table-striped table-hover table-dense">
                                    <thead>
                                        <tr>
                                            <th>
                                                <Trans id="name" />
                                            </th>
                                            <th>
                                                <Trans id="code" />
                                            </th>
                                            <th style={{ width: '10%' }}>
                                                <Trans id="detail" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groups.map((group) => {
                                            return (
                                                <tr key={group.id}>
                                                    <td>{group.label}</td>
                                                    <td>{group.code}</td>
                                                    <td>
                                                        <span
                                                            onClick={() =>
                                                                this.handleShowDetails(group.id)
                                                            }
                                                            className="c-hand text-primary"
                                                        >
                                                            <i className="icon icon-search" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        fromInternalPage: getFromInternalPage(state), // Mappa il selettore per `fromInternalPage`
    }
}

export default MatchWhenRole(connect(mapStateToProps)(UserGroupsList), 'admin')
